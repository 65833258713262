import React from "react";
import { Button, Form, Label } from "semantic-ui-react";

interface FilterProps {
    selectedStadiums: string[];
    availableStadiums: string[];
    selectedSports: string[];
    availableSports: string[];
    selectedTags: string[];
    availableTags: string[];
    isUser: boolean;
    onSubmitCallback: () => void;
    setStadiums: (stadiums: string[]) => void;
    setSports: (sports: string[]) => void;
    setTags: (tags: string[]) => void;
}

export class CrossValidationFilter extends React.Component<FilterProps> {
    handleSubmit = () => {
        this.props.onSubmitCallback();
    };

    setStadiums = (stadiums: string[]) => {
        this.props.setStadiums(stadiums);
    };

    setSports = (sports: string[]) => {
        this.props.setSports(sports);
    };

    setTags = (tags: string[]) => {
        this.props.setTags(tags);
    };

    render() {
        const {
            selectedStadiums,
            selectedSports,
            selectedTags,
            availableSports,
            availableStadiums,
            availableTags,
            isUser,
        } = this.props;
        return (
            <Form>
                <Form.Group widths={"equal"}>
                    <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                        <Label style={{ fontSize: "18px" }}> Select stadiums</Label>
                        <Form.Dropdown
                            required
                            clearable
                            fluid
                            multiple
                            search
                            selection
                            style={{
                                fontSize: "18px",
                                height: `${50 + selectedStadiums.length * 10}px`,
                                width: isUser ? "200px" : "300px",
                            }}
                            options={availableStadiums.map((name: string) => {
                                return { text: name, value: name };
                            })}
                            onChange={(event, data) => {
                                this.setStadiums(data.value as string[]);
                            }}
                        />
                    </div>

                    <div
                        style={{
                            marginLeft: "20px",
                            marginTop: "10px",
                            marginRight: "20px",
                        }}
                    >
                        <Label style={{ fontSize: "18px" }}> Select sports</Label>

                        <Form.Dropdown
                            required
                            clearable
                            fluid
                            multiple
                            search
                            selection
                            style={{
                                fontSize: "18px",
                                height: `${50 + selectedSports.length * 10}px`,
                                width: isUser ? "200px" : "300px",
                            }}
                            options={availableSports.map((name: string) => {
                                return { text: name, value: name };
                            })}
                            onChange={(event, data) => {
                                this.setSports(data.value as string[]);
                            }}
                        />
                    </div>

                    <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                        <Label style={{ fontSize: "18px" }}> Select tags</Label>
                        <Form.Dropdown
                            required
                            clearable
                            fluid
                            multiple
                            search
                            selection
                            style={{
                                fontSize: "18px",
                                height: `${50 + selectedTags.length * 10}px`,
                                width: isUser ? "400px" : "500px",
                            }}
                            menuStyle={{ overflowY: "auto", maxHeight: "50px" }}
                            options={availableTags.map((name: string) => {
                                return { text: name, value: name };
                            })}
                            onChange={(event, data) => {
                                this.setTags(data.value as string[]);
                            }}
                        />
                    </div>
                </Form.Group>
                <div
                    style={{
                        marginTop: "20px",
                        marginLeft: "20px",
                    }}
                >
                    <Form.Group>
                        <Button
                            style={{ maxHeight: "50px" }}
                            color="green"
                            onClick={this.handleSubmit}
                        >
                            Submit
                        </Button>
                    </Form.Group>
                </div>
            </Form>
        );
    }
}
