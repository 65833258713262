import * as d3 from "d3";
import { getCircleStatsColor } from "../../helpers/CrossValidation";
import React from "react";

interface ScatterPlotProps {
    userTimeStats: {
        completionTime: number;
        fatigue: number;
        annotationType: string;
    }[];
    width: number;
    height: number;
    margin: { top: number; right: number; bottom: number; left: number };
}

export class CrossValidationScatterPlot extends React.Component<ScatterPlotProps> {
    async componentDidMount(): Promise<void> {
        this.renderScatterPlot();
    }

    async componentDidUpdate(prevProps: Readonly<ScatterPlotProps>): Promise<void> {
        this.renderScatterPlot();
    }

    renderScatterPlot = () => {
        const { userTimeStats, width, height, margin } = this.props;

        // Removes previous chats before drawing the next one.
        d3.select("#scatterplot").selectAll("*").remove();

        const scatteplot = d3
            .select("#scatterplot")
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g") // appending a graphic element.
            .attr("transform", `translate(${margin.left},${margin.top})`);

        // Add scale range
        const xScale = d3
            .scaleLinear()
            .domain([
                (d3.min(userTimeStats, (d) => d.completionTime) as number) * 0.8,
                (d3.max(userTimeStats, (d) => d.completionTime) as number) * 1.2,
            ])
            .range([0, width]);

        const yScale = d3
            .scaleLinear()
            .domain([
                (d3.min(userTimeStats, (d) => d.fatigue) as number) * 0.8,
                (d3.max(userTimeStats, (d) => d.fatigue) as number) * 1.2,
            ])
            .range([height, 0]);

        // Add axis
        const xAxis = scatteplot
            .append("g")
            .attr("class", "x-axis")
            .attr("transform", `translate(0,${height})`)
            .call(d3.axisBottom(xScale));
        const yAxis = scatteplot.append("g").attr("class", "y-axis").call(d3.axisLeft(yScale));

        // Axis titles
        scatteplot
            .append("text")
            .attr("x", width / 2)
            .attr("y", height + margin.bottom - 10)
            .style("text-anchor", "middle")
            .text("Annotating Time (minutes)");

        scatteplot
            .append("text")
            .attr("transform", "rotate(-90)")
            .attr("x", -height / 2)
            .attr("y", -margin.left + 10)
            .attr("class", "axis-label")
            .style("text-anchor", "middle")
            .text("Fatigue (minutes)");

        //Add zoom
        const zoom = d3
            .zoom()
            .scaleExtent([0.5, 5])
            .on("zoom", function (event) {
                const newX = event.transform.rescaleX(xScale);
                const newY = event.transform.rescaleY(yScale);

                xAxis.call(d3.axisBottom(newX));
                yAxis.call(d3.axisLeft(newY));

                circles
                    .attr("cx", (d) => newX(d.completionTime))
                    .attr("cy", (d) => newY(d.fatigue))
                    .attr("r", 10 * event.transform.k);

                tooltipText
                    .attr("x", (d) => newX(d.completionTime))
                    .attr("y", (d) => newY(d.fatigue) - 30);
            });

        scatteplot.call(zoom as any);

        scatteplot
            .append("rect")
            .attr("width", width)
            .attr("height", height)
            .style("fill", "none")
            .style("pointer-events", "all")
            .attr("transform", `translate(${margin.left},${margin.top})`)
            .call(zoom as any);

        // Represent the data with circles
        const circles = scatteplot
            .selectAll("circle")
            .data(userTimeStats)
            .enter()
            .append("circle")
            .attr("cx", (d) => xScale(d.completionTime))
            .attr("cy", (d) => yScale(d.fatigue))
            .attr("r", 5)
            .style("fill", (d) => {
                return getCircleStatsColor(d.annotationType);
            });

        // Add tooltip
        const tooltipText = scatteplot
            .selectAll("tooltip")
            .data(userTimeStats)
            .enter()
            .append("g")
            .style("opacity", 0)
            .attr("class", "tooltip")
            .attr("id", (d, i) => `tooltipText-${i}`);

        // Background rectangle for the tooltip
        tooltipText
            .append("rect")
            .attr("width", 200)
            .attr("height", 50)
            .attr("x", width - 200)
            .attr("y", 20)
            .attr("rx", 5)
            .attr("ry", 5)
            .attr("fill", "white")
            .attr("stroke", "purple")
            .attr("stroke-width", 1);

        tooltipText
            .append("text")
            .attr("x", width - 100)
            .attr("y", 40)
            .attr("text-anchor", "middle")
            .attr("font-size", "12px")
            .attr("fill", "black")
            .text((d) => `Annotating Time: ${d.completionTime} min`);

        tooltipText
            .append("text")
            .attr("x", width - 100)
            .attr("y", 60)
            .attr("text-anchor", "middle")
            .attr("font-size", "12px")
            .attr("fill", "black")
            .text((d) => `Fatigue: ${d.fatigue.toFixed(2)} min`);

        // Add cicle events
        circles
            .on("mouseover", function (event, d) {
                // On hover, make the tooltip orange, and show tooltip
                d3.select(this).style("fill", "purple");

                d3.select(tooltipText.nodes()[userTimeStats.indexOf(d)]).style("opacity", 1);
            })
            .on("mouseout", function (event, d) {
                const annotationType = d.annotationType;
                const color = getCircleStatsColor(annotationType);
                d3.select(this).style("fill", color); // Reset circle color

                // Hide the text tooltip
                d3.select(tooltipText.nodes()[userTimeStats.indexOf(d)]).style("opacity", 0);
            });
    };

    render() {
        return <div id="scatterplot"></div>;
    }
}
