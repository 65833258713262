import * as React from "react";
import { Container, Card, Button, Header, Loader, Label } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { AppStore, ProvidedAppStore } from "../store/AppStore";
import { Task, TaskState } from "../models/Task";
import { AbortRequests } from "./helpers/AbortRequests";
import { pdfButton } from "./helpers/AnnotationInfo";
import HwkflowGuide from "../guides/Hwkflow - Navigation Guide.pdf";
import { MediaTask } from "../store/MediaStore";

enum TaskType {
    Annotation,
    Verification,
}

interface TaskboardState {
    classicTasks: Task[];
    mediaTasks: MediaTask[];
}

@inject("store")
@observer
export class Taskboard
    extends React.Component<ProvidedAppStore, TaskboardState>
    implements AbortRequests
{
    store: AppStore | undefined;
    controller: AbortController = new AbortController();
    mounted: boolean = false;

    constructor(props: ProvidedAppStore) {
        super(props);
        this.store = this.props.store;
        this.state = { classicTasks: [], mediaTasks: [] };
        this.fetchTasks = this.fetchTasks.bind(this);
    }

    fetchTasks() {
        if (!this.store) return;

        this.store.hwkflowClient
            .fetchTasks(false, this.controller.signal)
            .then((response) => this.setState({ classicTasks: response.data as Task[] }))
            .catch(function (error) {
                console.log(error);
            });

        this.store.mediaStore.fetchTasks().then((tasks) => this.setState({ mediaTasks: tasks }));
    }

    async componentDidMount() {
        this.mounted = true;
        await this.fetchTasks();
    }

    async componentWillUnmount() {
        this.mounted = false;
        this.controller.abort();
    }

    generateClassicTaskCard(task: Task, type: TaskType) {
        return (
            <Card
                key={`classic_${type}_${task.model.name}`}
                as={Link}
                to={`/model/${task.model.name}/${
                    type === TaskType.Annotation ? "annotation" : "verification"
                }/task/${task.id}`}
            >
                <Card.Content>
                    <Card.Header>{task.model.name}</Card.Header>
                    <Card.Description>{task.description}</Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <Button fluid color={"green"} basic>
                        {type === TaskType.Annotation ? "Annotate" : "Verify"}
                    </Button>
                </Card.Content>
            </Card>
        );
    }

    generateMediaTaskCard(task: MediaTask, type: TaskType) {
        return (
            <Card
                key={`media_${type}_${task.id}`}
                as={Link}
                to={`/media/task/${type === TaskType.Annotation ? "annotate" : "verify"}/${
                    task.id
                }`}
            >
                <Card.Content>
                    <Card.Header>{task.name}</Card.Header>
                    <Card.Description>
                        {task.annotationTypes.map((annotationType, index) => {
                            return (
                                <Label
                                    style={{ margin: "4px" }}
                                    key={`${annotationType.category}_${annotationType.type}_${index}`}
                                >
                                    {annotationType.category}, {annotationType.type}
                                </Label>
                            );
                        })}
                    </Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <Button fluid color={"green"} basic>
                        {type === TaskType.Annotation ? "Annotate" : "Verify"}
                    </Button>
                </Card.Content>
            </Card>
        );
    }

    render() {
        if (!this.store) return <Loader active />;

        const mediaAnnotationCardsJSX = this.state.mediaTasks
            .map((task) => {
                if (task.state !== TaskState.InProgress) return;
                return this.generateMediaTaskCard(task, TaskType.Annotation);
            })
            .filter((card) => card !== undefined);

        const classicAnnotationCardsJSX = this.state.classicTasks
            .map((task: Task) => {
                if (task.state !== TaskState.InProgress) return;
                return this.generateClassicTaskCard(task, TaskType.Annotation);
            })
            .filter((card) => card !== undefined);

        const classicVerificationCardsJSX = this.state.classicTasks
            .map((task: Task) => {
                if (
                    task.state !== TaskState.InProgress &&
                    task.state !== TaskState.AllAnnotated &&
                    task.state !== TaskState.NoData
                ) {
                    return;
                }
                return this.generateClassicTaskCard(task, TaskType.Verification);
            })
            .filter((card) => card !== undefined);

        let hwkflowGuideJSX = pdfButton(HwkflowGuide, "View Hwkflow Guide");
        return (
            <Container>
                {hwkflowGuideJSX}
                <Header>Media Annotation</Header>
                <Card.Group itemsPerRow={3}>
                    {mediaAnnotationCardsJSX.length > 0 ? (
                        mediaAnnotationCardsJSX
                    ) : (
                        <Container>No tasks for Annotation.</Container>
                    )}
                </Card.Group>
                <Header>Classic Annotation</Header>
                <Card.Group itemsPerRow={4}>
                    {classicAnnotationCardsJSX.length > 0 ? (
                        classicAnnotationCardsJSX
                    ) : (
                        <Container>No tasks for Annotation.</Container>
                    )}
                </Card.Group>
                <Header>Classic Verification</Header>
                <Card.Group itemsPerRow={4}>
                    {classicVerificationCardsJSX.length > 0 ? (
                        classicVerificationCardsJSX
                    ) : (
                        <Container>No tasks for verification.</Container>
                    )}
                </Card.Group>
            </Container>
        );
    }
}
