import React from "react";

import { TrackedCTX } from "../../helpers/TrackedSVG";
import { Instance, KeyPointDescription, Keypoint } from "../../../models/model_types/keypoints";
import { drawKeypointInstance } from "../../media/annotation/Keypoints";
import { User } from "../../../models/User";

const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");

function toSVGColor(color: string) {
    const svgColors = [
        { color: "red", svgColor: "#DB2828" },
        { color: "green", svgColor: "#16ab39" },
        { color: "orange", svgColor: "#F2711C" },
        { color: "yellow", svgColor: "#FBBD08" },
        { color: "olive", svgColor: "#B5CC18" },
        { color: "teal", svgColor: "#00B5AD" },
        { color: "blue", svgColor: "#2185D0" },
        { color: "violet", svgColor: "#6435C9" },
        { color: "purple", svgColor: "#A333C8" },
        { color: "pink", svgColor: "#E03997" },
        { color: "brown", svgColor: "#A5673F" },
        { color: "grey", svgColor: "#767676" },
        { color: "black", svgColor: "#27292a" },
    ];

    const svgColor = svgColors.find((elem) => elem.color === color);

    if (!svgColor) {
        return "grey";
    }

    return svgColor.svgColor;
}

export interface InstanceGroup {
    color: string;
    instances: Instance[];
}

export interface InstanceGroupCrossValidation extends InstanceGroup {
    opacity: number;
    user: User;
}

interface ImageProps {
    url: string;
    groups: InstanceGroup[] | InstanceGroupCrossValidation[];
    definition: KeyPointDescription[];

    onClick?: (button: number, x: number, y: number) => void;
    onMove?: (x: number, y: number) => void;
}

function isCrossValidation(
    group: InstanceGroup | InstanceGroupCrossValidation
): group is InstanceGroupCrossValidation {
    return (group as InstanceGroupCrossValidation).opacity !== undefined;
}

export class Image extends React.Component<ImageProps> {
    private canvasElement?: HTMLCanvasElement;
    private zoomCanvasElement?: HTMLCanvasElement;
    private imageElement?: HTMLImageElement;
    private divElement?: HTMLDivElement;
    private imageScale: number = 0;
    private beingDragged: boolean = false;
    private dragStarted: boolean = false;

    private trackedCTX?: TrackedCTX;
    private lastClick: SVGPoint;
    private lastPos: SVGPoint;
    private translation: { x: number; y: number };
    private delta: { x: number; y: number };
    private canvasElementSize: number = 680;
    private zoomCanvasElementSize: number = 150;

    constructor(props: ImageProps) {
        super(props);

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseWheel = this.onMouseWheel.bind(this);
        this.onResize = this.onResize.bind(this);

        this.drawAnnotatedImage = this.drawAnnotatedImage.bind(this);
        this.drawInstance = this.drawInstance.bind(this);
        this.estimateCanvasSize = this.estimateCanvasSize.bind(this);

        this.lastClick = svg.createSVGPoint();
        this.lastPos = svg.createSVGPoint();
        this.translation = { x: 0, y: 0 };
        this.delta = { x: 0, y: 0 };
    }

    componentDidMount() {
        if (this.canvasElement == null || this.imageElement == null || this.divElement == null) {
            console.warn("mounted without dom refs");
            return;
        }

        this.canvasElement.oncontextmenu = function (event: MouseEvent) {
            event.preventDefault();
        };

        this.estimateCanvasSize(this.canvasElementSize, this.canvasElement);

        this.lastPos.x = 0;
        this.lastPos.y = 0;
        this.lastClick = this.lastPos;

        const ctx = this.canvasElement.getContext("2d");
        if (ctx) {
            this.trackedCTX = new TrackedCTX(ctx);
        }

        this.canvasElement.onmousedown = this.onMouseDown;
        this.canvasElement.onmousemove = this.onMouseMove;
        this.canvasElement.onmouseup = this.onMouseUp;
        this.canvasElement.onwheel = this.onMouseWheel;
        this.canvasElement.addEventListener("wheel", this.onMouseWheel, false);

        window.addEventListener("resize", this.onResize, true);

        this.imageElement.onload = () => {
            if (
                this.canvasElement == null ||
                this.divElement == null ||
                this.imageElement == null
            ) {
                console.warn("updated without dom refs");
                return;
            }

            this.estimateCanvasSize(this.canvasElementSize, this.canvasElement);

            const ctx = this.canvasElement.getContext("2d");
            if (ctx) {
                this.trackedCTX = new TrackedCTX(ctx);
            }
            this.drawAnnotatedImage();
        };
    }

    componentDidUpdate(prevProps: Readonly<ImageProps>) {
        this.drawAnnotatedImage();
    }

    render() {
        return (
            <div ref={(child: HTMLDivElement) => (this.divElement = child)}>
                <canvas
                    ref={(child: HTMLCanvasElement) => (this.canvasElement = child)}
                    width={`100%`}
                    height={`100%`}
                    style={{ border: "1px solid gray" }}
                />
                <canvas
                    ref={(child: HTMLCanvasElement) => (this.zoomCanvasElement = child)}
                    width={150}
                    height={150}
                    style={{
                        border: "1px solid gray",
                        position: "relative",
                        bottom: "0px",
                        right: "-5px",
                    }}
                />
                <img
                    alt="keypoint wizard"
                    ref={(child: HTMLImageElement) => (this.imageElement = child)}
                    src={this.props.url}
                    style={{ display: "none" }}
                />
            </div>
        );
    }

    // Adapt canvas size to the image size.
    private estimateCanvasSize(maxCanvasSize: number, canvas: HTMLCanvasElement) {
        if (this.imageElement == null) {
            console.warn("updated without dom refs");
            return;
        }
        const maxImageSize = Math.max(this.imageElement.width, this.imageElement.height);
        canvas.width = this.imageElement.width / (maxImageSize / maxCanvasSize);
        canvas.height = this.imageElement.height / (maxImageSize / maxCanvasSize);
    }

    private onMouseDown(event: MouseEvent) {
        this.beingDragged = false;
        this.dragStarted = true;

        if (!this.trackedCTX) {
            console.warn(`onMouseDown without trackedCTX...`);
            return;
        }

        this.lastClick = this.trackedCTX.transformedPoint(event.offsetX, event.offsetY);
        this.drawAnnotatedImage();
    }

    private onMouseMove(event: MouseEvent) {
        if (!this.trackedCTX) {
            console.warn(`onMouseMove without trackedCTX...`);
            return;
        }

        this.lastPos = this.trackedCTX.transformedPoint(event.offsetX, event.offsetY);

        if (this.dragStarted) {
            this.beingDragged = true;

            const deltaX = this.lastPos.x - this.lastClick.x;
            const deltaY = this.lastPos.y - this.lastClick.y;
            this.delta.x += deltaX;
            this.delta.y += deltaY;

            this.trackedCTX.translate(deltaX, deltaY);
            this.drawAnnotatedImage();
        }

        if (!this.props.onMove) {
            return;
        }

        const imageX = this.lastPos.x / this.imageScale;
        const imageY = this.lastPos.y / this.imageScale;

        if (imageX < 0 || imageX >= this.imageElement!.width) {
            return;
        }

        if (imageY < 0 || imageY >= this.imageElement!.height) {
            return;
        }

        this.props.onMove(imageX, imageY);
    }

    private onMouseWheel(event: WheelEvent) {
        if (!this.trackedCTX) {
            console.warn(`onMouseWheel without trackedCTX...`);
            return;
        }

        event.preventDefault();

        const wheelDelta = event.deltaY ? -event.deltaY / 130 : -event.detail ? event.detail : 0;

        const scaleFactor = 1.05;
        const zoomFactor = Math.pow(scaleFactor, wheelDelta);

        this.translation = this.trackedCTX.scale(zoomFactor, this.lastPos, this.translation);

        this.drawAnnotatedImage();
    }

    private onMouseUp(event: MouseEvent) {
        const wasBeingDragged = this.beingDragged && this.dragStarted;
        this.beingDragged = false;
        this.dragStarted = false;
        if (wasBeingDragged) {
            return;
        }

        if (!this.props.onClick) {
            return;
        }

        if (!this.trackedCTX) {
            console.warn(`mouse up without trackedCTX...`);
            return;
        }

        const canvasPoint = this.trackedCTX.transformedPoint(event.offsetX, event.offsetY);
        const imageX = canvasPoint.x / this.imageScale;
        const imageY = canvasPoint.y / this.imageScale;

        if (imageX < 0 || imageX >= this.imageElement!.width) {
            return;
        }

        if (imageY < 0 || imageY >= this.imageElement!.height) {
            return;
        }

        this.props.onClick(event.button, imageX, imageY);
    }

    private onResize() {
        if (this.canvasElement == null || this.divElement == null) {
            return;
        }

        this.estimateCanvasSize(this.canvasElementSize, this.canvasElement);
        this.drawAnnotatedImage();
    }

    private drawInstance(
        ctx: CanvasRenderingContext2D,
        keypoints: Keypoint[],
        definition: KeyPointDescription[],
        color: string,
        isCrossValidation: boolean,
        opacity: number
    ) {
        if (!this.trackedCTX) {
            console.warn(`drawInstance without trackedCTX...`);
            return;
        }

        drawKeypointInstance(
            ctx,
            this.trackedCTX,
            keypoints,
            definition,
            color,
            this.imageScale,
            isCrossValidation,
            opacity
        );
    }

    private getDrawImageSizes(
        canvas: HTMLCanvasElement,
        image: HTMLImageElement,
        to_save: boolean
    ) {
        const scaleWidth = canvas.width / image.width;
        const scaleHeight = canvas.height / image.height;

        const scaleZoom = scaleWidth < scaleHeight ? scaleWidth : scaleHeight;
        if (to_save) {
            this.imageScale = scaleZoom;
        }

        const canvasImageWidth = image.width * scaleZoom;
        const canvasImageHeight = image.height * scaleZoom;

        return { width: canvasImageWidth, height: canvasImageHeight };
    }

    private drawAnnotatedImage() {
        if (
            this.canvasElement == null ||
            this.zoomCanvasElement == null ||
            this.imageElement == null
        ) {
            console.warn("mounted without dom refs");
            return;
        }

        if (!this.trackedCTX) {
            console.warn(`onMouseWheel without trackedCTX...`);
            return;
        }

        const ctx = this.canvasElement.getContext("2d");
        const ctxZoom = this.zoomCanvasElement.getContext("2d");
        if (!ctx || !ctxZoom) {
            return;
        }

        ctx.save();
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
        ctx.restore();

        const imageDrawSizes = this.getDrawImageSizes(this.canvasElement, this.imageElement, true);
        ctx.drawImage(this.imageElement, 0, 0, imageDrawSizes.width, imageDrawSizes.height);

        const { groups, definition } = this.props;

        for (const group of groups) {
            const { color, instances } = group;

            if (isCrossValidation(group)) {
                this.canvasElementSize = 580;
                this.zoomCanvasElementSize = 100;
                const { opacity } = group;
                for (const instance of instances) {
                    this.drawInstance(ctx, instance.keypoints, definition, color, true, opacity);
                }
            } else {
                for (const instance of instances) {
                    this.drawInstance(ctx, instance.keypoints, definition, color, false, 1);
                }
            }
        }

        // Draw zoom preview
        const scale = this.trackedCTX.getScale();
        this.estimateCanvasSize(this.zoomCanvasElementSize, this.zoomCanvasElement);
        const imageDrawSizesZoom = this.getDrawImageSizes(
            this.zoomCanvasElement,
            this.imageElement,
            false
        );
        ctxZoom.drawImage(
            this.imageElement,
            0,
            0,
            imageDrawSizesZoom.width,
            imageDrawSizesZoom.height
        );

        // Add zoom rectagle
        ctxZoom.strokeStyle = "red";
        ctxZoom.lineWidth = 2;
        if (scale === 1) {
            this.delta = { x: 0, y: 0 };
        }

        const widthScale = this.zoomCanvasElement.width / this.canvasElement.width;
        const heightScale = this.zoomCanvasElement.height / this.canvasElement.height;
        const rectX = ((this.lastPos.x - this.delta.x) * (scale - 1) * widthScale) / scale;
        const rectY = ((this.lastPos.y - this.delta.y) * (scale - 1) * heightScale) / scale;
        const rectWidth = this.zoomCanvasElement.width / scale;
        const rectHeight = this.zoomCanvasElement.height / scale;
        ctxZoom.strokeRect(rectX, rectY, rectWidth, rectHeight);
    }
}
