import { inject } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import { ProvidedAppStore } from "../../../store/AppStore";
import { Label, Header, Icon, HeaderContent } from "semantic-ui-react";
import { CrossValidationFilter } from "../CrossValidationFilter";
import { CrossValidationScatterPlot } from "../plots/ScatterPlot";
import { CrossValidationPiePlot } from "../plots/PiePlot";

interface State {
    modelType: string;
    userVisibilityStats: [];
    userTimeStats: {
        completionTime: number;
        fatigue: number;
        annotationType: string;
    }[];
    availableSports: string[];
    selectedSports: string[];
    availableStadiums: string[];
    selectedStadiums: string[];
    availableTags: string[];
    selectedTags: string[];
}

// D3 graph dimensions
const margin = { top: 10, right: 0, bottom: 40, left: 50 };
const width = 600 - margin.left - margin.right;
const height = 600 - margin.top - margin.bottom;

type Props = ProvidedAppStore & RouteComponentProps;
@inject("store")
export class CrossValidationGeneralStats extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            modelType: "",
            userVisibilityStats: [],
            userTimeStats: [],
            availableSports: [],
            selectedSports: [],
            availableStadiums: [],
            selectedStadiums: [],
            availableTags: [],
            selectedTags: [],
        };
    }

    async componentDidMount(): Promise<void> {
        window.scrollTo(0, 0);
        const routesParams = this.fetchRouteParameters();
        this.setState({ modelType: routesParams.modelType });

        const filterOptions =
            await this.props.store?.hwkflowClient.fetchCrossValidationMetricsFilterOptions(
                routesParams.modelType
            );

        this.setState({
            availableSports: filterOptions?.data.availableSports,
            availableStadiums: filterOptions?.data.availableStadiums,
            availableTags: filterOptions?.data.tags,
        });

        const userVisibilityData =
            await this.props.store?.crossValidationStore.generalCrossValidationStats(
                routesParams.modelType,
                this.state.selectedStadiums,
                this.state.selectedSports,
                this.state.selectedTags
            );

        this.setState({
            userVisibilityStats: userVisibilityData.userVisibilityData,
            userTimeStats: userVisibilityData.time,
        });
    }

    fetchRouteParameters() {
        const { params } = this.props.match;
        const { userId, modelType } = params as any;
        return { userId: parseInt(userId), modelType: modelType };
    }

    handleCallback = () => {
        this.handleFilterSubmit();
    };

    handleStadiumsCallback = (stadiums: string[]) => {
        this.setState({ selectedStadiums: stadiums });
    };

    handleSportsCallback = (sports: string[]) => {
        this.setState({ selectedSports: sports });
    };

    handleTagsCallback = (tags: string[]) => {
        this.setState({ selectedTags: tags });
    };

    handleFilterSubmit = async () => {
        let { selectedSports, selectedStadiums, selectedTags, modelType } = this.state;

        const userVisibilityData =
            await this.props.store?.crossValidationStore.generalCrossValidationStats(
                modelType,
                selectedStadiums,
                selectedSports,
                selectedTags
            );

        this.setState({
            userVisibilityStats: userVisibilityData.userVisibilityData,
            userTimeStats: userVisibilityData.time,
        });
    };

    render() {
        const {
            availableSports,
            availableStadiums,
            availableTags,
            selectedSports,
            selectedStadiums,
            selectedTags,
            userTimeStats,
            userVisibilityStats,
        } = this.state;
        return (
            <div>
                <Header
                    as="h1"
                    style={{ marginLeft: "500px" }}
                >{`GENERAL CROSS VALIDATION STATS`}</Header>
                <div
                    style={{
                        marginLeft: "200px",
                        display: "flex",
                        border: "1px solid orange",
                        padding: "10px",
                        borderRadius: "8px",
                        maxWidth: "1200px",
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginTop: "50px",
                    }}
                >
                    <Header as="h2" style={{ marginTop: "10px" }}>
                        <Icon name="options" color="orange" size="tiny" />
                        <HeaderContent> General Stats Filter Options</HeaderContent>
                    </Header>
                    <CrossValidationFilter
                        selectedSports={selectedSports}
                        availableSports={availableSports}
                        selectedTags={selectedTags}
                        availableTags={availableTags}
                        selectedStadiums={selectedStadiums}
                        availableStadiums={availableStadiums}
                        isUser={false}
                        onSubmitCallback={this.handleCallback}
                        setStadiums={this.handleStadiumsCallback}
                        setSports={this.handleSportsCallback}
                        setTags={this.handleTagsCallback}
                    ></CrossValidationFilter>
                </div>
                <div>
                    <div
                        style={{
                            display: "flex",
                            paddingTop: "50px",
                        }}
                    >
                        <div
                            style={{
                                textAlign: "center",
                                maxWidth: "800px",

                                marginLeft: "200px",
                            }}
                        >
                            <Header as="h2">Fatigue vs annotating time</Header>
                            <CrossValidationScatterPlot
                                userTimeStats={userTimeStats}
                                width={width}
                                height={height}
                                margin={margin}
                            />
                        </div>
                        <div
                            style={{
                                textAlign: "center",
                                maxWidth: "800px",
                                flex: 1,
                            }}
                        >
                            <Header as="h2">Annotation visibility</Header>
                            <CrossValidationPiePlot
                                userVisibilityStats={userVisibilityStats}
                                height={height}
                                width={width}
                                margin={margin}
                            />
                        </div>
                    </div>
                    <div>
                        <div
                            style={{
                                marginLeft: "650px",
                                border: "1px solid gray",
                                maxWidth: "130px",
                            }}
                        >
                            <Label
                                style={{
                                    marginRight: "10px",
                                    backgroundColor: "#649426",
                                    marginLeft: "5px",
                                }}
                            />
                            {`Annotated`}
                            <Label
                                style={{
                                    marginRight: "15px",
                                    backgroundColor: "#e2275f",
                                    marginLeft: "5px",
                                }}
                            />
                            {`Discarded`}
                            <Label
                                style={{
                                    marginRight: "5px",
                                    backgroundColor: "steelblue",
                                    marginLeft: "5px",
                                }}
                            />
                            {`Not Annotated`}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
