import * as React from "react";
import { Container, Card, Button, Header, Icon, Loader, Label, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { ProvidedAppStore } from "../../../store/AppStore";
import { Task } from "../../../models/Task";
import { AbortRequests } from "../../helpers/AbortRequests";
import { ModelType } from "../../../models/ModelType";

interface CrossValidationState {
    currentTasks: Task[];
    currentModels: ModelType[];
    headers: string[];
    isLoaded: boolean;
    visibleDatasets: string[];
}

@inject("store")
@observer
export class CrossValidationGeneralStatsMenu
    extends React.Component<ProvidedAppStore, CrossValidationState>
    implements AbortRequests
{
    controller: AbortController = new AbortController();
    mounted: boolean = false;

    constructor(props: ProvidedAppStore) {
        super(props);
        this.state = {
            currentTasks: [],
            currentModels: [],
            headers: ["Classification", "KeyPoints", "Metric", "ObjectDetection"],
            isLoaded: false,
            visibleDatasets: [],
        };

        this.fetchModelTypes = this.fetchModelTypes.bind(this);
    }

    handleHeaderToggle = (type: string) => {
        this.setState((prevState) => {
            const { visibleDatasets } = prevState;
            if (visibleDatasets.includes(type)) {
                return {
                    visibleDatasets: visibleDatasets.filter((dataset) => dataset !== type),
                };
            } else {
                return { visibleDatasets: [...visibleDatasets, type] };
            }
        });
    };

    fetchModelTypes() {
        this.props
            .store!.hwkflowClient.fetchCrossValidationModelTypes()
            .then((response) =>
                this.setState({ currentModels: response.data as ModelType[], isLoaded: true })
            )
            .catch(function (error) {
                console.log("failed to fetch model types");
            });
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.mounted = true;
        this.fetchModelTypes();
    }

    async componentWillUnmount() {
        this.mounted = false;
        this.controller.abort();
    }

    createCards(type: string) {
        const cards = this.state.currentModels
            .filter((model) => model.type === type)
            .map((model) => (
                <Card key={model.id}>
                    <Card.Content>
                        <Card.Header size="small">{model.name}</Card.Header>
                    </Card.Content>
                    <Card.Content extra>
                        <Button
                            fluid
                            color={"green"}
                            basic
                            as={Link}
                            to={`/cross-validation/${model.id}`}
                        >
                            View stats
                        </Button>
                    </Card.Content>
                </Card>
            ));

        if (cards.length === 0) {
            return <Label style={{ marginBottom: "20px" }}>No cross validation data</Label>;
        }

        return cards;
    }

    render() {
        const { headers } = this.state;
        return (
            <Container>
                <Header size="huge" style={{ marginBottom: "50px", marginTop: "50px" }}>
                    Cross Validation General Stats
                </Header>
                {headers.map((type) => (
                    <div key={type}>
                        <Header
                            size="huge"
                            onClick={() => this.handleHeaderToggle(type)}
                            style={{
                                cursor: "pointer",
                                display: "flex",
                                marginBottom: "50px",
                            }}
                        >
                            <Icon
                                name={
                                    this.state.visibleDatasets.includes(type)
                                        ? "chevron down"
                                        : "chevron right"
                                }
                                size="tiny"
                                style={{ marginLeft: "10px" }}
                                color="olive"
                            />
                            {type}
                        </Header>

                        {this.state.visibleDatasets.includes(type) && !this.state.isLoaded ? (
                            <Loader active inline="centered" />
                        ) : this.state.visibleDatasets.includes(type) ? (
                            <Card.Group style={{ margin: "50px", marginBottom: "25px" }}>
                                {this.createCards(type)}
                            </Card.Group>
                        ) : null}
                        <Divider style={{ margin: "50px", marginBottom: "25px" }} />
                    </div>
                ))}
            </Container>
        );
    }
}
