import * as d3 from "d3";
import React from "react";

interface BoxPlotProps {
    userVisibilityStats: [];
    width: number;
    height: number;
    margin: { top: number; right: number; bottom: number; left: number };
}

export class CrossValidationPiePlot extends React.Component<BoxPlotProps> {
    private svgVisbilityPieChart = React.createRef<SVGSVGElement>();

    async componentDidMount(): Promise<void> {
        this.renderPieChart();
    }

    async componentDidUpdate(prevProps: Readonly<BoxPlotProps>): Promise<void> {
        this.renderPieChart();
    }

    renderPieChart = async () => {
        let { userVisibilityStats, width, height, margin } = this.props;
        const radius = Math.min(width, height) / 2 - margin.bottom - margin.left;

        // Remove previous pie chart before drawing the next one
        d3.select(this.svgVisbilityPieChart.current).selectAll("*").remove();

        const svg = d3
            .select(this.svgVisbilityPieChart.current)
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", `translate(${width / 2}, ${height / 2})`);

        // Adapt the data to d3 requirements.
        const pieData = Object.entries(userVisibilityStats)
            .filter(([keys, value]) => value !== 0 && value !== null)
            .map(([key, value]) => ({
                label: key,
                value: value,
            }));

        // Return No data availabe message if there is no annotations.
        if (pieData.length === 0) {
            svg.append("text")
                .attr("x", 20)
                .attr("y", 20)
                .attr("text-anchor", "middle")
                .attr("dy", ".35em")
                .text("No Data Available")
                .style("font-size", "16px")
                .style("fill", "gray");
            return;
        }

        const pie = d3.pie<{ label: string; value: number }>().value((d) => d.value);
        const arc = d3.arc().outerRadius(radius).innerRadius(80);
        const color = d3.scaleOrdinal(d3.schemePastel1);

        const arcData = pie(pieData);

        svg.selectAll("path")
            .data(arcData)
            .enter()
            .append("path")
            .attr("d", (d) => arc({ ...d, innerRadius: 0, outerRadius: radius }) as string)
            .attr("fill", (d, i) => color(i.toString()));

        // Add polylines to link the tooltip with the pie chart
        svg.selectAll("polyline")
            .data(arcData)
            .enter()
            .append("polyline")
            .attr("points", (d) => {
                const centroid = arc.centroid(d as any);
                const labelPosition = [centroid[0] * 1.5, centroid[1] * 1.5];
                let horizontalEnd, verticalEnd;

                if (centroid[0] > 0) {
                    // adapt the position acording to the centroid
                    horizontalEnd = [labelPosition[0] + 30, labelPosition[1]];
                } else {
                    horizontalEnd = [labelPosition[0] - 30, labelPosition[1]];
                }

                verticalEnd = [labelPosition[0], labelPosition[1]];
                return `${centroid[0]},${centroid[1]} ${verticalEnd[0]},${verticalEnd[1]} ${horizontalEnd[0]},${horizontalEnd[1]}`;
            })
            .attr("stroke", "#000")
            .attr("fill", "none")
            .attr("stroke-width", 1);

        // Add tooltip text
        svg.selectAll("text")
            .data(arcData)
            .enter()
            .append("text")
            .attr("transform", (d) => {
                const centroid = arc.centroid(d as any);
                const labelPosition = [centroid[0] * 1.5, centroid[1] * 1.5];
                let horizontalEnd;

                if (centroid[0] > 0) {
                    horizontalEnd = [labelPosition[0] + 30, labelPosition[1] + 20];
                } else {
                    horizontalEnd = [labelPosition[0] - 30, labelPosition[1] - 20];
                }

                return "translate(" + horizontalEnd + ")";
            })
            .attr("dy", "0.35em")
            .attr("text-anchor", "middle")
            .html((d) => {
                return `<tspan x="0" dy="0">${d.data.label}</tspan><tspan x="0" dy="1.2em">${(
                    d.data.value * 100
                ).toFixed(2)}%</tspan>`;
            });
    };

    render() {
        return <svg ref={this.svgVisbilityPieChart} style={{ marginLeft: "100px" }}></svg>;
    }
}
