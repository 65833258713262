import { inject, observer } from "mobx-react";
import React from "react";
import { ProvidedAppStore } from "../../../store/AppStore";
import { RouteComponentProps } from "react-router";
import { Container, Header, Loader } from "semantic-ui-react";
import { MediaAnnotationPage } from "../MediaAnnotation";

interface State {
    currentMediaId?: number;
}

type Props = ProvidedAppStore & RouteComponentProps;

@inject("store")
@observer
export class DirectMediaAnnotation extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    fetchRouteParameters() {
        const { params } = this.props.match;
        const { id } = params as any;
        return { id: parseInt(id) };
    }

    async componentDidMount() {
        const mediaId = this.fetchRouteParameters().id;
        this.setState({ currentMediaId: mediaId });
    }

    render() {
        if (this.state.currentMediaId === undefined) {
            return <Loader active />;
        }

        return (
            <Container>
                <Header as="h2">Annotating Media {this.state.currentMediaId}</Header>
                <MediaAnnotationPage mediaId={this.state.currentMediaId} />
            </Container>
        );
    }
}
