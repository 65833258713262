import { inject } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import { ProvidedAppStore } from "../../../store/AppStore";
import { Image, Card, Label, Icon, Header, HeaderContent } from "semantic-ui-react";
import { User } from "../../../models/User";
import { CrossValidationFilter } from "../CrossValidationFilter";
import { CrossValidationScatterPlot } from "../plots/ScatterPlot";
import { CrossValidationPiePlot } from "../plots/PiePlot";

interface State {
    modelType: string;
    userVisibilityStats: [];
    userTimeStats: {
        completionTime: number;
        fatigue: number;
        annotationType: string;
    }[];
    user?: User;
    availableSports: string[];
    selectedSports: string[];
    availableStadiums: string[];
    selectedStadiums: string[];
    availableTags: string[];
    selectedTags: string[];
}

// D3 graph dimensions
const margin = { top: 10, right: 20, bottom: 40, left: 50 };
const width = 700 - margin.left - margin.right;
const height = 600 - margin.top - margin.bottom;

type Props = ProvidedAppStore & RouteComponentProps;
@inject("store")
export class CrossValidationUserStats extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            modelType: "",
            userVisibilityStats: [],
            userTimeStats: [],
            availableSports: [],
            selectedSports: [],
            availableStadiums: [],
            selectedStadiums: [],
            availableTags: [],
            selectedTags: [],
        };
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        const routesParams = this.fetchRouteParameters();
        this.setState({ modelType: routesParams.modelType });

        let user = await this.props.store?.hwkflowClient.fetchUserById(routesParams.userId);
        if (user) {
            const filterOptions =
                await this.props.store?.hwkflowClient.fetchCrossValidationMetricsFilterOptions(
                    this.state.modelType,
                    user.data.id
                );

            this.setState({
                user: user.data,
                availableSports: filterOptions?.data.availableSports,
                availableStadiums: filterOptions?.data.availableStadiums,
                availableTags: filterOptions?.data.tags,
            });
        }
    }

    fetchRouteParameters() {
        const { params } = this.props.match;
        const { userId, modelType } = params as any;
        return { userId: parseInt(userId), modelType: modelType };
    }

    async componentDidUpdate(prevProps: Props, prevState: State): Promise<void> {
        if (this.state.modelType === prevState.modelType && this.state.user === prevState.user)
            return;

        if (!this.state.user) return;

        const userVisibilityData =
            await this.props.store?.crossValidationStore.userCrossValidationStats(
                this.state.modelType,
                this.state.user.id,
                this.state.selectedStadiums,
                this.state.selectedSports,
                this.state.selectedTags
            );
        this.setState({
            userVisibilityStats: userVisibilityData.userVisibilityData,
            userTimeStats: userVisibilityData.time,
        });
    }

    handleFilterSubmit = async () => {
        let { selectedSports, selectedStadiums, selectedTags, user } = this.state;

        if (user) {
            const userVisibilityData =
                await this.props.store?.crossValidationStore.userCrossValidationStats(
                    this.state.modelType,
                    user.id,
                    selectedStadiums,
                    selectedSports,
                    selectedTags
                );
            this.setState({
                userVisibilityStats: userVisibilityData.userVisibilityData,
                userTimeStats: userVisibilityData.time,
            });
        }
    };

    handleStadiumsCallback = (stadiums: string[]) => {
        this.setState({ selectedStadiums: stadiums });
    };

    handleSportsCallback = (sports: string[]) => {
        this.setState({ selectedSports: sports });
    };

    handleTagsCallback = (tags: string[]) => {
        this.setState({ selectedTags: tags });
    };

    render() {
        const {
            user,
            availableSports,
            availableStadiums,
            availableTags,
            selectedSports,
            selectedStadiums,
            selectedTags,
            userTimeStats,
            userVisibilityStats,
        } = this.state;

        const userJSX = user ? (
            <div
                style={{
                    display: "flex",
                    border: "1px solid pink",
                    padding: "10px",
                    borderRadius: "8px",
                    maxWidth: "400px",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    alignItems: "center",
                }}
            >
                <Card>
                    <Image src={user.profilePicture} wrapped ui={true} />
                </Card>
                <div style={{ marginLeft: "20px" }}>
                    <Header as="h1">{user.name}</Header>
                    <Header as="h5">{user.email}</Header>
                    <Header as="h5">{"Department: " + user.department}</Header>
                    <Header as="h5">{user.status}</Header>
                </div>
            </div>
        ) : null;
        return (
            <>
                <Header as="h1" style={{ marginLeft: "400px" }}>
                    {`${user?.name.toUpperCase()} CROSS VALIDATION STATS`}
                </Header>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        marginBottom: "10px",
                        width: "300px",
                        marginLeft: "100px",
                    }}
                >
                    {userJSX}

                    <div
                        style={{
                            marginLeft: "50px",
                            display: "flex",
                            border: "1px solid orange",
                            padding: "10px",
                            borderRadius: "8px",
                            maxWidth: "950px",
                            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >
                        <Header as="h2" style={{ marginTop: "10px" }}>
                            <Icon name="options" color="orange" size="tiny" />
                            <HeaderContent>User Stats Filter Options</HeaderContent>
                        </Header>

                        <CrossValidationFilter
                            selectedSports={selectedSports}
                            availableSports={availableSports}
                            selectedTags={selectedTags}
                            availableTags={availableTags}
                            selectedStadiums={selectedStadiums}
                            availableStadiums={availableStadiums}
                            isUser={true}
                            onSubmitCallback={this.handleFilterSubmit}
                            setStadiums={this.handleStadiumsCallback}
                            setSports={this.handleSportsCallback}
                            setTags={this.handleTagsCallback}
                        ></CrossValidationFilter>
                    </div>
                </div>

                <div>
                    <div
                        style={{
                            display: "flex",
                            paddingTop: "50px",
                        }}
                    >
                        <div
                            style={{
                                textAlign: "center",
                                maxWidth: "800px",

                                marginLeft: "100px",
                            }}
                        >
                            <h2>Fatigue vs annotating time</h2>
                            <CrossValidationScatterPlot
                                userTimeStats={userTimeStats}
                                width={width}
                                height={height}
                                margin={margin}
                            />
                        </div>
                        <div
                            style={{
                                textAlign: "center",
                                maxWidth: "800px",
                                flex: 1,
                            }}
                        >
                            <h2>Annotation visibility</h2>
                            <CrossValidationPiePlot
                                userVisibilityStats={userVisibilityStats}
                                height={height}
                                width={width}
                                margin={margin}
                            />
                        </div>
                    </div>
                    <div>
                        <div
                            style={{
                                marginLeft: "650px",
                                border: "1px solid gray",
                                maxWidth: "130px",
                            }}
                        >
                            <Label
                                style={{
                                    marginRight: "10px",
                                    backgroundColor: "#649426",
                                    marginLeft: "5px",
                                }}
                            />
                            {`Annotated`}
                            <Label
                                style={{
                                    marginRight: "15px",
                                    backgroundColor: "#e2275f",
                                    marginLeft: "5px",
                                }}
                            />
                            {`Discarded`}
                            <Label
                                style={{
                                    marginRight: "5px",
                                    backgroundColor: "steelblue",
                                    marginLeft: "5px",
                                }}
                            />
                            {`Not Annotated`}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
