import { inject, observer } from "mobx-react";
import React from "react";
import { ProvidedAppStore } from "../../../store/AppStore";
import { RouteComponentProps } from "react-router";
import { Container, Header, Icon, Loader, Message } from "semantic-ui-react";
import { MediaAnnotationPage } from "../MediaAnnotation";
import { MediaStore, MediaTask } from "../../../store/MediaStore";
import { AnnotationTypesDropDown } from "../../helpers/Tasks";

interface State {
    task?: MediaTask;
    currentMediaId?: number;
    noMediaReason?: "ALL_ANNOTATED" | "NO_DATA";
}

type Props = ProvidedAppStore & RouteComponentProps;

@inject("store")
@observer
export class MediaTaskAnnotation extends React.Component<Props, State> {
    store: MediaStore | undefined;

    constructor(props: Props) {
        super(props);
        this.store = props.store?.mediaStore;
        this.state = {};
    }

    async componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<State>,
        snapshot?: any
    ) {
        if (prevState.task !== this.state.task) {
            this.fetchMediaId();
        }
    }

    async componentDidMount() {
        const taskId = this.fetchRouteParameters().id;
        this.fetchTask(taskId);
    }

    fetchRouteParameters() {
        const { params } = this.props.match;
        const { id } = params as any;
        return { id: parseInt(id) };
    }

    async fetchTask(id: number) {
        if (!this.store) return;
        const task = await this.store.fetchTask(id);
        this.setState({ task: task });
    }

    async fetchMediaId() {
        if (!this.store) return;

        const result = await this.store.fetchMediaIdForTaskAnnotation(this.state.task!.id);

        if (result === "NO_DATA") {
            this.setState({ noMediaReason: "NO_DATA" });
        } else if (result === "ALL_ANNOTATED") {
            this.setState({ noMediaReason: "ALL_ANNOTATED" });
        } else {
            this.setState({ currentMediaId: result });
        }
    }

    render() {
        if (!this.state.task) return <Loader active />;

        const noMediaReason = this.state.noMediaReason;
        const allAnnotated = noMediaReason === "ALL_ANNOTATED";

        if (!noMediaReason && !this.state.currentMediaId) return <Loader active />;
        if (noMediaReason) {
            return (
                <Container>
                    <Message positive={allAnnotated}>
                        {allAnnotated ? "All media for task annotated!" : "No media available."}
                    </Message>
                </Container>
            );
        }

        return (
            <Container>
                <Container
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: "8px",
                    }}
                >
                    <Header as="h2">
                        Annotating Media {this.state.currentMediaId} for task '
                        {this.state.task.name}'
                    </Header>
                    <AnnotationTypesDropDown
                        annotationTypes={this.state.task.annotationTypes}
                        trigger={
                            <Message style={{ margin: "0 0 0 0" }}>
                                Required Annotation Types
                                <Icon name="dropdown" />
                            </Message>
                        }
                    />
                </Container>
                {this.state.task.instructions !== "" && (
                    <Message
                        icon
                        style={{
                            backgroundColor: "#E9F4F4",
                            borderRadius: "8px",
                            border: "1px solid #D6E8E8",
                            color: "#2D9C9C",
                        }}
                    >
                        <Icon name="info circle" color="teal" />
                        <Message.Content>
                            <Message.Header>Task Instructions:</Message.Header>
                            {this.state.task.instructions}
                        </Message.Content>
                    </Message>
                )}
                <MediaAnnotationPage
                    mediaId={this.state.currentMediaId!}
                    requiredAnnotationTypes={this.state.task.annotationTypes}
                    taskId={this.state.task.id}
                    getNextMediaId={() => this.fetchMediaId()}
                />
            </Container>
        );
    }
}
