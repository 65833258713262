export async function getFrameFromImage(url: string): Promise<ImageBitmap> {
    let image = new Image();
    image.src = url;
    await image.decode();
    return await createImageBitmap(image);
}

export async function getFramesFromVideoTrack(
    track: MediaStreamVideoTrack,
    takeEvery: number,
    keepFrames: number[],
    maxGap: number
): Promise<Map<number, ImageBitmap>> {
    const processor = new MediaStreamTrackProcessor({ track: track });
    const reader = processor.readable.getReader();

    let frames: Map<number, ImageBitmap> = new Map();
    let finished: boolean = false;

    let frameCount = 0;
    let lastSavedTimestep = 0;
    let lastFrame: VideoFrame | undefined = undefined;
    while (!finished) {
        const { done, value } = await reader.read();

        if (value) {
            // If the gap between the last saved timestep and the next keep frame is less than maxGap, we can skip all frames until then.
            const nextFrameToKeep =
                keepFrames.find((t) => t > frameCount) || Number.MAX_SAFE_INTEGER;
            const isNextFrameToKeepWithinMaxGap = nextFrameToKeep - lastSavedTimestep < maxGap;
            const shouldTakeFrameAndNextIsOutOfGap =
                frameCount % takeEvery === 0 && !isNextFrameToKeepWithinMaxGap;

            const shouldSaveCurrentFrame =
                frameCount === 0 ||
                // isFinalFrame ||
                keepFrames.includes(frameCount) ||
                shouldTakeFrameAndNextIsOutOfGap;
            if (shouldSaveCurrentFrame) {
                const bitmap = await createImageBitmap(value);
                frames.set(frameCount, bitmap);
                lastSavedTimestep = frameCount;
            }

            if (lastFrame) lastFrame.close();
            lastFrame = value;
        }
        if (done && lastFrame) {
            finished = true;
            frames.set(frameCount, await createImageBitmap(lastFrame));
            lastFrame.close();
        }
        frameCount++;
    }
    return frames;
}

// note(Alex.Shaw): This is currently unavailable in Typescript, but is
// supported by all modern browsers (except Safari)
// https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/captureStream
interface HTMLVideoElementWithCaptureStream extends HTMLVideoElement {
    captureStream(): MediaStream;
}

export async function getVideoTrack(url: string, previewElement: HTMLVideoElement) {
    const video = previewElement as HTMLVideoElementWithCaptureStream;
    video.crossOrigin = "anonymous";
    video.muted = true;
    video.src = url;
    await video.play();
    const [track] = video.captureStream().getVideoTracks();
    video.onended = (evt) => track.stop();
    return track;
}
