import React from "react";
import { Header, Table, Container, Grid, Input, Segment, Loader } from "semantic-ui-react";
import { ProvidedAppStore } from "../../../store/AppStore";
import { RouteComponentProps } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { AbortRequests } from "../../helpers/AbortRequests";
import { User } from "../../../models/User";

type Props = RouteComponentProps & ProvidedAppStore;

interface State {
    users: User[];
    usersOfInterest: User[];
    selectedUser: string;
    searchString: string;
}

@inject("store")
@observer
export class CrossValidationUserMenu
    extends React.Component<Props, State>
    implements AbortRequests
{
    controller: AbortController = new AbortController();
    mounted: boolean = false;

    constructor(props: Props) {
        super(props);
        this.state = {
            users: [],
            usersOfInterest: [],
            selectedUser: "",
            searchString: "",
        };
        this.searchChange = this.searchChange.bind(this);
    }

    searchChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { users } = this.state;
        let usersOfInterest = users.filter((user: User) => {
            const searchString = this.state.searchString.toLowerCase();
            const name = user.name.toLowerCase();
            const email = user.email.toLowerCase();
            const department = user.department.toLowerCase();

            const values = [name, email, department];
            return values.some((value: string) => {
                return value.includes(searchString);
            });
        });

        if (usersOfInterest.length == 0 || event.target.value.length == 0) {
            usersOfInterest = users;
        }

        this.setState({ searchString: event.target.value, usersOfInterest });
    }

    async componentDidMount() {
        this.mounted = true;
        const users = await this.props.store?.crossValidationStore.fetchCrossValidationUsers();

        this.setState({ users: users?.data, usersOfInterest: users?.data });
    }

    async componentWillUnmount() {
        this.mounted = false;
        this.controller.abort();
    }

    render() {
        let { usersOfInterest } = this.state;

        const usersJSX =
            usersOfInterest.length == 0 ? (
                <Loader active inline="centered" />
            ) : (
                usersOfInterest.map((user: any) => {
                    let activeJSX = <div />;

                    return (
                        <Table.Row
                            key={user.id}
                            onClick={() => {
                                this.props.history.push(`/cross-validation/users/${user.id}`);
                            }}
                        >
                            <Table.Cell>{activeJSX}</Table.Cell>
                            <Table.Cell collapsing={true} className="text-break">
                                {user.name}
                            </Table.Cell>
                            <Table.Cell>{user.department}</Table.Cell>
                            <Table.Cell className="users-email">{user.email}</Table.Cell>
                        </Table.Row>
                    );
                })
            );

        return (
            <Container>
                <Header as="h1">Cross validation user stats</Header>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <Input
                                icon="search"
                                placeholder="Search..."
                                fluid={true}
                                onChange={this.searchChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <Segment>
                                <Table compact selectable={true} width={12}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell></Table.HeaderCell>
                                            <Table.HeaderCell
                                                style={{
                                                    paddingRight: "150px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                Name
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>Department</Table.HeaderCell>
                                            <Table.HeaderCell>Email</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>{usersJSX}</Table.Body>
                                </Table>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}
