import React from "react";
import {
    Button,
    Card,
    Container,
    Grid,
    Header,
    Icon,
    Label,
    List,
    Loader,
    Message,
    Modal,
    Popup,
    Progress,
    SemanticICONS,
} from "semantic-ui-react";
import { inject, observer } from "mobx-react";

import { ProvidedAppStore } from "../../../store/AppStore";
import { MediaStore, MediaTask } from "../../../store/MediaStore";
import { TaskPriority, TaskState } from "../../../models/Task";
import { AnnotationTypesDropDown, getProgressBarColourAndPercentage } from "../../helpers/Tasks";
import { v4 as uuidv4 } from "uuid";
import { ImageState } from "../../../models/Image";
import { Toast, ToastPayload } from "../../helpers/Toast";
import { Link } from "react-router-dom";

const TOAST_DATA: Record<string, ToastPayload> = {
    success: { message: "Task topped up successfully." },
    noAction: { message: "Task already has all required media in 'Needs Annotation'." },
    failed: { message: "Failed to top up task.", type: "negative" },
};

interface TaskListProps {
    tasks: MediaTask[];
    refreshTasks: CallableFunction;
}

type Props = TaskListProps & ProvidedAppStore;

interface State {
    confirmationModalOpen: boolean;
    confirmationModalCallback: CallableFunction;
    confirmationModalText?: string;
    currentToastData?: ToastPayload;
    showToast?: boolean;
}

@inject("store")
@observer
export class MediaTaskList extends React.Component<Props, State> {
    store: MediaStore | undefined;

    constructor(props: Props) {
        super(props);
        this.store = props.store?.mediaStore;
        this.state = { confirmationModalOpen: false, confirmationModalCallback: () => {} };
    }

    async startPauseOrResumeTask(task: MediaTask) {
        let newState = task.state;
        if (task.state === TaskState.Incoming) {
            newState = TaskState.InProgress;
        } else if (task.state === TaskState.InProgress || task.state === TaskState.AllAnnotated) {
            newState = TaskState.OnHold;
        } else if (task.state === TaskState.OnHold) {
            newState = task.annotated > task.target ? TaskState.AllAnnotated : TaskState.InProgress;
        }
        await this.updateTaskState(task, newState);
    }

    async updateTaskState(task: MediaTask, newState: TaskState) {
        await this.store?.updateTask(task.id, newState);
        this.props.refreshTasks();
    }

    getStartPauseResumeButtonIcon(taskState: TaskState): SemanticICONS {
        if ([TaskState.InProgress, TaskState.AllAnnotated].includes(taskState)) {
            return "pause";
        }
        return "play";
    }

    getStartPauseResumeButtonHelpText(taskState: TaskState): string {
        if (taskState === TaskState.Incoming) {
            return "Start Task";
        } else if ([TaskState.InProgress, TaskState.AllAnnotated].includes(taskState)) {
            return "Pause Task";
        } else if (taskState === TaskState.OnHold) {
            return "Resume Task";
        }
        return "Cant start, pause or resume from the current state.";
    }

    topUpTask(task: MediaTask) {
        // note(alex.shaw): Locked to incoming for now until FE work done to add selector
        this.store?.topUpTask(task.id, ImageState.Incoming).then((response) => {
            let toastData = TOAST_DATA.failed;
            if (response.status === 200) {
                toastData = TOAST_DATA.success;
            } else if (response.status === 204) {
                toastData = TOAST_DATA.noAction;
            }

            this.setState({ showToast: true, currentToastData: toastData });
            this.props.refreshTasks();
        });
    }

    clearConfirmationModal() {
        this.setState({
            ...this.state,
            confirmationModalCallback: () => {},
            confirmationModalText: undefined,
            confirmationModalOpen: false,
        });
    }

    setupTaskStateChangeConfirmationModal(task: MediaTask, desiredState: TaskState) {
        this.setState({
            confirmationModalOpen: true,
            confirmationModalText: `Mark '${task.name}' as ${desiredState}?`,
            confirmationModalCallback: () => {
                this.updateTaskState(task, desiredState);
                this.clearConfirmationModal();
            },
        });
    }

    setupTopupConfirmationModal(task: MediaTask) {
        this.setState({
            confirmationModalOpen: true,
            confirmationModalText: `Top up task '${task.name}' with media from 'Incoming'?`,
            confirmationModalCallback: () => {
                this.topUpTask(task);
                this.clearConfirmationModal();
            },
        });
    }

    taskPriorityIcon(priority: TaskPriority) {
        if (priority === TaskPriority.High) return <Icon name="angle double up" color="red" />;
        else if (priority === TaskPriority.Medium) return <Icon name="angle up" color="orange" />;
        return <Icon name="angle double down" color="green" />;
    }

    generateLabelsForArray(array: string[]) {
        if (array.length === 0) return "All";

        return array.map((val, index) => (
            <Label style={{ marginBottom: "4px" }} key={`${index}_${uuidv4()}`}>
                {val}
            </Label>
        ));
    }

    renderMediaTaskCard(task: MediaTask) {
        let { percentage, colour } = getProgressBarColourAndPercentage(task.state);

        const taskSportsJsx = this.generateLabelsForArray(task.sports);
        const taskStadiumsJsx = this.generateLabelsForArray(task.stadiums);
        const taskApplicationsJsx = this.generateLabelsForArray(task.applications);
        const taskTagsJsx = this.generateLabelsForArray(task.tags);

        return (
            <Card fluid key={task.id}>
                {/* Task information */}
                <Container
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "0 8px 0 8px",
                    }}
                >
                    {this.taskPriorityIcon(task.priority)}
                    <Grid columns={1} container>
                        <Grid.Column>
                            <Grid.Row style={{ fontWeight: "bold" }}>{task.name}</Grid.Row>
                            {task.instructions !== "" && <Grid.Row>{task.instructions}</Grid.Row>}
                        </Grid.Column>
                    </Grid>
                    <AnnotationTypesDropDown
                        annotationTypes={task.annotationTypes}
                        trigger={
                            <Container style={{ flex: "0 0 12%" }}>
                                Annotation Types
                                <Icon name="dropdown" />
                            </Container>
                        }
                    />
                    <Progress
                        style={{ flex: "0 0 25%", marginRight: "4px" }}
                        percent={percentage}
                        autoSuccess
                        color={colour}
                        label={task.state}
                        size="medium"
                    />
                    <Popup
                        trigger={
                            <Button
                                icon="up arrow"
                                disabled={task.state === TaskState.Complete}
                                onClick={() => this.setupTopupConfirmationModal(task)}
                            />
                        }
                        content={"Top up Task"}
                    />
                    <Popup
                        trigger={
                            <Button
                                icon={this.getStartPauseResumeButtonIcon(task.state)}
                                disabled={[TaskState.Complete, TaskState.NoData].includes(
                                    task.state
                                )}
                                onClick={() => this.startPauseOrResumeTask(task)}
                            />
                        }
                        content={this.getStartPauseResumeButtonHelpText(task.state)}
                    />
                    <Popup
                        trigger={
                            <Button
                                positive
                                icon="check"
                                disabled={task.state === TaskState.Complete}
                                onClick={() =>
                                    this.setupTaskStateChangeConfirmationModal(
                                        task,
                                        TaskState.Complete
                                    )
                                }
                            />
                        }
                        content={"Complete Task"}
                    />
                    <Popup
                        trigger={
                            <Button
                                negative
                                icon="lock"
                                onClick={() =>
                                    this.setupTaskStateChangeConfirmationModal(
                                        task,
                                        TaskState.Archived
                                    )
                                }
                            />
                        }
                        content={"Archive Task"}
                    />
                    <Link to={`/media/task/annotate/${task.id}`}>
                        <Button>Annotate</Button>
                    </Link>
                </Container>
                {/* Extra information popup */}
                <Popup
                    wide="very"
                    trigger={
                        <Icon
                            name="info circle"
                            color="grey"
                            style={{ position: "absolute", padding: "0 6px 0 6px" }}
                        />
                    }
                    content={
                        <Grid padded columns={12} rows={6}>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <Header sub>Sports:</Header>
                                    {taskSportsJsx}
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Header sub>Stadiums:</Header>
                                    {taskStadiumsJsx}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <Header sub>Applications:</Header>
                                    {taskApplicationsJsx}
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Header sub>Tags:</Header>
                                    {taskTagsJsx}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <Header sub>To Annotate:</Header>
                                    {task.needsAnnotation}
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Header sub>To Verify:</Header>
                                    {task.needsVerification}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <Header sub>Discarded/Annotated/Verified/Target</Header>
                                    {`${task.discarded}/${task.annotated}/${task.verified}/${task.target}`}
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Header sub>Deadline</Header>
                                    {new Date(task.deadline).toDateString()}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <Header sub>Ealiest Upload</Header>
                                    {new Date(task.earliestUploadDate).toDateString()}
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Header sub>Latest Upload</Header>
                                    {new Date(task.latestUploadDate).toDateString()}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <Header sub>Created</Header>
                                    {new Date(task.createdAt).toDateString()}
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Header sub>Updated</Header>
                                    {new Date(task.updatedAt).toDateString()}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    }
                />
            </Card>
        );
    }

    render() {
        if (!this.store) return <Loader active />;
        if (this.props.tasks.length == 0) {
            return <Message>No tasks found. Try creating one!</Message>;
        }

        return (
            <Container>
                <Modal
                    closeIcon
                    open={this.state.confirmationModalOpen}
                    onClose={() => this.clearConfirmationModal()}
                >
                    <Header content={this.state.confirmationModalText} />
                    <Modal.Content>Are you sure? This is permanent.</Modal.Content>
                    <Modal.Actions>
                        <Button
                            color="green"
                            content="No"
                            onClick={() => this.clearConfirmationModal()}
                        />
                        <Button
                            color="red"
                            content="Yes"
                            onClick={() => this.state.confirmationModalCallback()}
                        />
                    </Modal.Actions>
                </Modal>
                <Toast
                    showToast={this.state.showToast}
                    setShowToast={(show: boolean) => {
                        this.setState({ showToast: show });
                    }}
                    {...this.state.currentToastData}
                />
                {this.props.tasks.map((task) => this.renderMediaTaskCard(task))}
            </Container>
        );
    }
}
