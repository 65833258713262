import React from "react";

import { Container, Icon, List, Popup, SemanticCOLORS } from "semantic-ui-react";
import { TaskState } from "../../models/Task";
import {
    AnnotationCategories,
    AnnotationTypeResponse,
    AnnotationTypes,
} from "../../store/MediaStore";
const PERCENT_KEYS: { [state: string]: any } = {
    Incoming: { number: 0, colour: "grey" },
    InProgress: { number: 33, colour: "yellow" },
    AllAnnotated: { number: 66, colour: "orange" },
    Complete: { number: 100, colour: "green" },
    OnHold: { number: 50, colour: "red" },
    NoData: { number: 0, colour: "red" },
    Training: { number: 100, colour: "green" },
};

export function getProgressBarColourAndPercentage(state: TaskState) {
    return {
        percentage: PERCENT_KEYS[state]["number"],
        colour: PERCENT_KEYS[state]["colour"] as SemanticCOLORS,
    };
}

// If required annotation types is specified, return true if the given category
// and type are present. Otherwise assume all types are required.
export function isAnnotationTypeRequired(
    requiredAnnotationTypes: AnnotationTypeResponse[] | undefined,
    category: AnnotationCategories,
    type: AnnotationTypes
) {
    if (requiredAnnotationTypes) {
        return (
            requiredAnnotationTypes.find((at) => at.category === category && at.type === type) !==
            undefined
        );
    }
    return true;
}

interface AnnotationTypesDropDownProps {
    annotationTypes: AnnotationTypeResponse[];
    trigger: React.JSX.Element;
}

export class AnnotationTypesDropDown extends React.Component<AnnotationTypesDropDownProps, {}> {
    render() {
        return (
            <Popup
                content={
                    <List>
                        {this.props.annotationTypes.map((annotationType, index) => {
                            return (
                                <List.Item
                                    key={`${annotationType.category}_${annotationType.type}_${index}`}
                                >
                                    {annotationType.category}, {annotationType.type}
                                </List.Item>
                            );
                        })}
                    </List>
                }
                trigger={this.props.trigger}
            />
        );
    }
}
