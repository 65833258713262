import React from "react";
import { inject } from "mobx-react";

import { Table, Header, Grid, Accordion, Icon, Button, Segment, Label } from "semantic-ui-react";
import {
    Instance,
    Point,
    objectColor,
    ObjectDetectionDescription,
} from "../../../models/model_types/object_detection";
import { Mode } from "./Annotation";
import { DrawingInstance, Image, InstanceGroup } from "./Image";
import { Image as ImageModel } from "../../../models/Image";
import { FrameSelect } from "../../helpers/FrameSelect";
import { ProvidedAppStore } from "../../../store/AppStore";
import { findClosestPointIndex } from "../../helpers/Mouse";

interface EditInstancesProps {
    updatedInstances: (instances: Instance[], mode: Mode) => void;
    instances: Instance[];
    objects: ObjectDetectionDescription[];
    image: ImageModel;
}

interface State {
    instances: Instance[];

    instanceIdx: number;
    vertexIdx: number;

    bucketPath: string;
    activeTags: boolean;
}

function closestInstance(x: number, y: number, instances: Instance[]) {
    let minDistance = Number.MAX_VALUE;
    let closestInstanceIdx = -1;
    let closestVertexIdx = -1;

    if (instances.length === 0) {
        return { minDistance, closestInstanceIdx, closestVertexIdx };
    }

    // First calculate the closest instance according to the points in the full shape
    // Then choose the clicked-in point which is nearest
    for (let instanceIdx = 0; instanceIdx < instances.length; ++instanceIdx) {
        const points = instances[instanceIdx].shape();
        const { pointDistance } = findClosestPointIndex(x, y, points);

        if (pointDistance < minDistance) {
            minDistance = pointDistance;
            closestInstanceIdx = instanceIdx;
        }
    }

    const { closestPointIdx } = findClosestPointIndex(x, y, instances[closestInstanceIdx].points);

    return {
        minDistance,
        closestInstanceIdx,
        closestVertexIdx: closestPointIdx,
    };
}
type Props = EditInstancesProps & ProvidedAppStore;
@inject("store")
export class EditInstances extends React.Component<Props, State> {
    private divElement?: HTMLDivElement;
    private dragStarted: boolean = false;

    private startingPoints: Point;
    private closestVertexIDX: number;
    private closestInstanceIDX: number;

    constructor(props: Props) {
        super(props);

        this.updatedFrame = this.updatedFrame.bind(this);
        this.handleAccordian = this.handleAccordian.bind(this);
        this.createVertex = this.createVertex.bind(this);
        this.deselect = this.deselect.bind(this);
        this.deleteVertex = this.deleteVertex.bind(this);
        this.onClick = this.onClick.bind(this);

        this.dragMove = this.dragMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        const { instances, image } = this.props;
        this.state = {
            instances: instances,
            instanceIdx: -1,
            vertexIdx: -1,
            bucketPath: image.bucketPath,
            activeTags: false,
        };

        this.startingPoints = { pixelX: -1, pixelY: -1, obscured: false, visible: false };
        this.closestVertexIDX = -1;
        this.closestInstanceIDX = -1;
    }

    componentDidMount() {
        if (this.divElement == null) {
            console.warn("mounted without dom refs");
            return;
        }
        this.divElement.onmouseup = this.onMouseUp;
    }

    componentDidUpdate(previousProps: Props) {
        if (previousProps !== this.props) {
            this.setState({
                instances: this.props.instances,
                instanceIdx: -1,
                vertexIdx: -1,
                bucketPath: this.props.image.bucketPath,
            });
        }
    }

    updatedFrame(value: string) {
        this.setState({ bucketPath: value });
    }

    handleAccordian(idx: number) {
        const { instanceIdx } = this.state;
        idx = instanceIdx === idx ? -1 : idx;
        this.setState({ instanceIdx: idx, vertexIdx: -1 });
    }

    createVertex(x: number, y: number, is_obscured: boolean, is_visible: boolean) {
        const { instances, instanceIdx, vertexIdx } = this.state;

        if (instanceIdx < 0 || vertexIdx < 0) {
            let { closestInstanceIdx, closestVertexIdx } = closestInstance(x, y, instances);
            // If we only add 1 vertex and edit the instance to add the next 2 points,
            // it will assign the third point as the 2nd bbox point if it is closer to the 1st point.
            // We use the following statement to avoid this bug, and assing the points correctly.
            const instance = instances[closestInstanceIdx];
            if (!instance) return;

            if (
                instances[closestInstanceIdx].shape_type === "centeredRectangle" &&
                instances[closestInstanceIdx].points.length === 2
            ) {
                closestVertexIdx = 1;
            }
            this.setState({
                instanceIdx: closestInstanceIdx,
                vertexIdx: closestVertexIdx,
            });
            return;
        }

        const instance = instances[instanceIdx];

        if (instance.points.length >= instance.max_points) {
            return;
        }

        // If the object has a polygonal shape, and we have pressed button 2, then we remove it. If not, we create the vertex.
        // If we have a rectagled or centeredRectangled shape, we create the vertex, taking into account the visibility constraints.
        if (instance.shape_type === "polygon" && is_obscured === false && is_visible === false) {
            this.deselect();
        } else {
            if (instance.shape_type !== "centeredRectangle" || instance.points.length < 2) {
                is_obscured = false;
                is_visible = true;
            }
            instance.points.splice(vertexIdx + 1, 0, {
                pixelX: x,
                pixelY: y,
                obscured: is_obscured,
                visible: is_visible,
            });
        }

        this.setState({ vertexIdx: -1, instanceIdx: -1 });
        this.props.updatedInstances(instances, Mode.Edit);
    }

    deselect() {
        this.setState({ vertexIdx: -1, instanceIdx: -1 });
    }

    deleteVertex(x: number, y: number) {
        // note(will.brennan) - find the closest keypoint within 10px (canvas) and delete it
        // set the current instanceIDX and definitionIdx acoordingly...

        let { instances } = this.state;
        const { minDistance, closestInstanceIdx, closestVertexIdx } = closestInstance(
            x,
            y,
            instances
        );

        if (minDistance > 10 || closestInstanceIdx < 0 || closestVertexIdx < 0) {
            return;
        }

        instances[closestInstanceIdx].points.splice(closestVertexIdx, 1);

        this.props.updatedInstances(instances, Mode.Edit);
        this.setState({
            instances: instances,
            instanceIdx: closestInstanceIdx,
            vertexIdx: closestVertexIdx,
        });
    }

    deleteInstance(idx: number) {
        let { instances } = this.state;
        instances.splice(idx, 1);
        this.setState({ instances, instanceIdx: -1, vertexIdx: -1 });
    }

    onClick(button: number, x: number, y: number) {
        if (button === 0) {
            this.createVertex(x, y, false, true);
        } else if (button === 1) {
            this.createVertex(x, y, true, true);
        } else if (button === 2) {
            this.createVertex(x, y, false, false);
        }
    }

    onMouseDown(button: number, x: number, y: number) {
        let { instances } = this.state;

        const { minDistance, closestInstanceIdx, closestVertexIdx } = closestInstance(
            x,
            y,
            instances
        );
        if (minDistance > 15 || closestInstanceIdx < 0 || closestVertexIdx < 0) {
            return;
        }

        this.setState({
            instanceIdx: closestInstanceIdx,
            vertexIdx: closestVertexIdx,
        });

        this.dragStarted = true;

        // Saving the points that are to be dragged and for which instance
        this.closestVertexIDX = closestVertexIdx;
        this.closestInstanceIDX = closestInstanceIdx;

        // Use to determine if the position has changed since the mouse button was pressed down
        this.startingPoints = instances[closestInstanceIdx].points[closestVertexIdx];
    }

    dragMove(x: number, y: number) {
        if (this.dragStarted && this.closestVertexIDX >= 0 && this.closestInstanceIDX >= 0) {
            // Updating the points of the instance if the mouse is being moved
            let { instances } = this.state;
            let points = instances[this.closestInstanceIDX].points;
            points[this.closestVertexIDX] = {
                pixelX: x,
                pixelY: y,
                obscured: points[this.closestVertexIDX].obscured,
                visible: true,
            };
            // Updating the state to update the instance in real time
            this.setState({ instances });
        }
    }

    private onMouseUp(event: MouseEvent) {
        if (event.button === 2) {
            this.dragStarted = false;
            let { instances } = this.state;

            if (this.closestInstanceIDX < 0 || this.closestVertexIDX < 0) {
                return;
            }
            // Getting the [updated] points
            const points = instances[this.closestInstanceIDX].points[this.closestVertexIDX];

            // Calculating the difference between points to deduce if it is being deleted
            const xDiff = this.startingPoints.pixelX - points.pixelX;
            const yDiff = this.startingPoints.pixelY - points.pixelY;

            if (yDiff === 0 && xDiff === 0) {
                this.deleteVertex(points.pixelX, points.pixelY);
            }

            this.closestVertexIDX = -1;
            this.closestInstanceIDX = -1;
            this.startingPoints = { pixelX: -1, pixelY: -1, obscured: false, visible: false };
        }
    }

    render() {
        const { instanceIdx, vertexIdx, instances, bucketPath, activeTags } = this.state;
        const { image, objects } = this.props;

        const ann = image.annotations[image.annotations.length - 1];
        let annComment = "";
        if (image.annotations.length > 0) {
            annComment = ann.comment;
        }

        let textAreaJSX = <div />;
        // Checking if there is a comment on the annotation
        if (!!annComment) {
            textAreaJSX = (
                <div>
                    <Segment>
                        <b>Reason for Decline:</b>
                        <Segment>{annComment}</Segment>
                    </Segment>
                </div>
            );
        }

        const instancesJSX = instances.map((instance: Instance, idx_i: number) => {
            const verticesJSX = instance.points.map((point: Point, idx_k: number) => {
                const fnOnClick = () => {
                    this.setState({
                        instanceIdx: idx_i,
                        vertexIdx: idx_k,
                    });
                };

                const isSameInstance = instanceIdx === idx_i;
                const isSameVertex = vertexIdx === idx_k;
                const isActive = isSameInstance && isSameVertex;

                return (
                    <Table.Row key={idx_k} onClick={fnOnClick} active={isActive}>
                        <Table.Cell>Point {String(idx_k)}</Table.Cell>
                    </Table.Row>
                );
            });

            return (
                <div key={idx_i}>
                    <Accordion.Title
                        active={instanceIdx === idx_i}
                        index={idx_i}
                        onClick={() => this.handleAccordian(idx_i)}
                    >
                        <Icon name="dropdown" />
                        {instance.label} {idx_i}
                    </Accordion.Title>
                    <Accordion.Content active={instanceIdx === idx_i}>
                        <Button color="red" onClick={() => this.deleteInstance(idx_i)}>
                            Delete {instance.label} {idx_i}
                        </Button>

                        <Table basic="very" celled={true} padded={true} selectable={true}>
                            <Table.Body>{verticesJSX}</Table.Body>
                        </Table>
                    </Accordion.Content>
                </div>
            );
        });

        const drawingInstances = instances.map((elem: Instance, idx: number): DrawingInstance => {
            const activeVertex = idx === instanceIdx ? vertexIdx : -1;
            return { instance: elem, activeVertex: activeVertex };
        });

        const activeInstances = drawingInstances.filter(
            (elem: DrawingInstance, index: number) => index === instanceIdx
        );
        const staticInstances = drawingInstances.filter(
            (elem: DrawingInstance, index: number) => index !== instanceIdx
        );

        const instanceObjects = instances.map(
            (i: Instance) =>
                objects.find(
                    (o: ObjectDetectionDescription) => o.label === i.label
                ) as ObjectDetectionDescription
        );

        const instanceGroups: InstanceGroup[] = [{ color: "yellow", instances: activeInstances }];
        for (let idx = 0; idx < instanceObjects.length; ++idx) {
            const object = instanceObjects[idx];
            const { label } = object;
            const color = objectColor(object);
            instanceGroups.push({
                color: color,
                instances: staticInstances.filter(
                    (elem: DrawingInstance) => elem.instance.label === label
                ),
            });
        }

        const setActiveTags = (activeTags: boolean) => {
            this.setState({ activeTags });
        };

        const tagItems = image.tags.map((tag) => (
            <Label key={tag.name} style={{ "margin-bottom": "7px" }}>
                {tag.name}
            </Label>
        ));

        let tagSegmentJSX = <div />;
        if (tagItems.length !== 0) {
            tagSegmentJSX = (
                <Accordion styled={true} fluid={true}>
                    <Accordion.Title
                        active={activeTags}
                        index={0}
                        onClick={() => {
                            setActiveTags(!activeTags);
                        }}
                    >
                        <Icon name="dropdown" />
                        Tags
                    </Accordion.Title>
                    <Accordion.Content active={activeTags}>{tagItems}</Accordion.Content>
                </Accordion>
            );
        }

        return (
            <Grid.Row>
                <Grid.Column width={4}>
                    <FrameSelect image={image} onSelect={this.updatedFrame} />
                    <Header
                        as="h3"
                        content="Missing Points"
                        subheader="Right click near point to delete. Left click near to select. When point is selected, left click again to insert new point before it in the shape."
                    />
                    <Accordion>{instancesJSX}</Accordion>
                    <hr />
                    {textAreaJSX}
                    <br />
                    {tagSegmentJSX}
                </Grid.Column>
                <Grid.Column width={12}>
                    <div ref={(child: HTMLDivElement) => (this.divElement = child)}>
                        <Image
                            url={this.props.store!.hwkflowClient.getImageUrl(bucketPath)}
                            groups={instanceGroups}
                            onClick={this.onClick}
                            onMouseDown={this.onMouseDown}
                            onMove={this.dragMove}
                        />
                    </div>
                </Grid.Column>
            </Grid.Row>
        );
    }
}
