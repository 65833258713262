import React from "react";
import { Container, Menu } from "semantic-ui-react";
import { NavLink, Switch, Redirect, RouteComponentProps } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { PrivateRoute, AdminRoute, VerifierRoute, StandardRoute } from "../auth/AuthRoutes";

import { Summary } from "./Summary";
import { Admin } from "./ModelAdmin";
import { DataExplorer } from "./DataExplorer";
import { History } from "./History";
import { ImageExplorer } from "../ImageExplorer/Explorer";
import { AnnotationWizard, VerificationWizard } from "../annotation/Annotation";

import { ProvidedAppStore } from "../../store/AppStore";
import { Taskboard } from "../TaskBoard";
import { Statsboard } from "../StatsBoard";
import { KeycloakRole } from "../../models/User";
import { Image } from "../../models/Image";

type Props = RouteComponentProps & ProvidedAppStore;

@inject("store")
@observer
export class ModelTypePage extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async setTask() {
        const { params } = this.props.match;
        const { task } = params as any;
        await this.props.store!.modelStore.setModelType(task);
        await this.props.store!.modelArchivedStore.setModelType(task);
    }

    async componentDidMount() {
        await this.setTask();
    }

    async componentDidUpdate(prevProps: Props) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            await this.setTask();
        }
    }

    onSubmit(image: Image) {
        this.props.store!.hwkflowClient.updateImage(image);
    }

    render() {
        const { params } = this.props.match;
        const { task } = params as any;

        const { user } = this.props.store!.userAuthStore;

        const links = [
            {
                url: `/model/${task}/summary`,
                name: "Summary",
                userLevel: KeycloakRole.ANNOTATOR,
            },
            {
                url: `/model/${task}/explore`,
                name: "Explore",
                userLevel: KeycloakRole.VERIFIER,
            },
            {
                url: `/model/${task}/annotation`,
                name: "Annotation",
                userLevel: KeycloakRole.ANNOTATOR,
            },
            {
                url: `/model/${task}/verification`,
                name: "Verification",
                userLevel: KeycloakRole.VERIFIER,
            },
            {
                url: `/model/${task}/history`,
                name: "Model History",
                userLevel: KeycloakRole.ADMIN,
            },
            {
                url: `/model/${task}/admin`,
                name: "Admin",
                userLevel: KeycloakRole.ADMIN,
            },
        ];

        const linksJSX = links.map((link, idx) => {
            if (user?.roles?.some((item) => item === link.userLevel)) {
                return (
                    <Menu.Item key={idx} as={NavLink} to={link.url}>
                        {link.name}
                    </Menu.Item>
                );
            } else {
                return <div key={idx} />;
            }
        });

        return (
            <Container className="main-content">
                <Menu secondary>{linksJSX}</Menu>
                <Switch>
                    <PrivateRoute path="/model/:task/summary" component={Summary} />
                    <VerifierRoute
                        path="/model/:task/explore/:page"
                        component={(props: Props) => (
                            <DataExplorer {...props} onSubmit={this.onSubmit} />
                        )}
                    />
                    <Redirect
                        from="/model/:task/explore"
                        exact={true}
                        to="/model/:task/explore/0"
                    />
                    <AdminRoute path="/model/:task/image/:id" component={ImageExplorer} />
                    <AdminRoute path="/model/:task/history" component={History} />
                    <PrivateRoute path="/taskboard" component={Taskboard} />
                    <PrivateRoute path="/statsboard" component={Statsboard} />
                    <StandardRoute
                        path="/model/:task/annotation/task/:taskId"
                        component={AnnotationWizard}
                    />
                    <VerifierRoute
                        path="/model/:task/annotation/image/:imageId"
                        component={AnnotationWizard}
                    />
                    <VerifierRoute path="/model/:task/annotation" component={AnnotationWizard} />
                    <VerifierRoute
                        path="/model/:task/verification/task/:taskId"
                        component={VerificationWizard}
                    />
                    <VerifierRoute
                        path="/model/:task/verification"
                        component={VerificationWizard}
                    />
                    <AdminRoute path="/model/:task/admin" component={Admin} />
                </Switch>
            </Container>
        );
    }
}
