import React from "react";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import { Provider, observer } from "mobx-react";

import { AppStore } from "./store/AppStore";
import { FailedAuth } from "./components/auth/AuthComponents";
import { SideBarLayout } from "./components/Sidebar";
import { Taskboard } from "./components/TaskBoard";
import { Statsboard } from "./components/StatsBoard";
import { BugReporting } from "./components/BugReports/BugReporting";
import { Profile } from "./components/helpers/Profile";
import { ModelTypePage } from "./components/model_type/Page";
import { AdminPage } from "./components/admin/Page";
import { PrivateRoute, StandardRoute, AdminRoute } from "./components/auth/AuthRoutes";

import "semantic-ui-css/semantic.min.css";
import { HwkFlowApiClient } from "./store/Client";
import { MediaExplorePage } from "./components/media/MediaExplorer";
import { MediaViewPage } from "./components/media/MediaView";
import { DirectMediaAnnotation } from "./components/media/annotation/DirectAnnotation";
import { MediaTaskAnnotation } from "./components/media/annotation/TaskAnnotation";
import SessionTimer from "./components/helpers/SessionTimer";
import { CrossValidationExplorerPage } from "./components/crossValidation/images/CrossValidationExplorer";
import { CrossValidationViewPage } from "./components/crossValidation/images/CrossValidationView";
import { CrossValidationAdminPage } from "./components/crossValidation/CrossValidationMainPage";
import { CrossValidationUserModelTypesMenu } from "./components/crossValidation/users/CrossValidationUserModelTypesMenu";
import { CrossValidationUserStats } from "./components/crossValidation/users/CrossValidationUserStats";
import { CrossValidationGeneralStats } from "./components/crossValidation/generalStats/CrossValidationGeneralStats";

interface FrontendEnvironment {
    HWKFLOW_BASE_URL: string;
}

interface AppProps {
    environment: FrontendEnvironment;
    frontendVersion?: string;
    backendVersion?: string;
}

@observer
class App extends React.Component<AppProps, {}> {
    store: AppStore;
    sessionCheckInterval: NodeJS.Timeout | undefined;

    constructor(props: AppProps) {
        super(props);
        const { HWKFLOW_BASE_URL } = props.environment;
        const { frontendVersion, backendVersion } = props;
        console.log(HWKFLOW_BASE_URL);
        this.store = new AppStore(
            new HwkFlowApiClient(HWKFLOW_BASE_URL!, frontendVersion, backendVersion)
        );
        const { userAuthStore } = this.store;
        userAuthStore.checkAuthentication();
    }

    render() {
        const { userAuthStore } = this.store;

        if (userAuthStore.waitForStatus) {
            return (
                <Dimmer active>
                    <Loader>
                        Checking user profile
                        <p>authenticated: {JSON.stringify(userAuthStore.user == null)}</p>
                    </Loader>
                </Dimmer>
            );
        }

        return (
            <Provider store={this.store}>
                <SessionTimer store={userAuthStore} />
                <BrowserRouter>
                    <Switch>
                        <Redirect from="/" exact={true} to="/taskboard" />
                        <AdminRoute
                            path="/statsboard"
                            layout={SideBarLayout}
                            component={Statsboard}
                        />
                        <StandardRoute
                            path="/taskboard"
                            layout={SideBarLayout}
                            component={Taskboard}
                        />
                        <StandardRoute
                            path="/BugReports/BugReporting"
                            layout={SideBarLayout}
                            component={BugReporting}
                        />
                        <StandardRoute
                            path="/model/:task"
                            layout={SideBarLayout}
                            component={ModelTypePage}
                        />
                        <StandardRoute
                            path="/media/explore/:page"
                            layout={SideBarLayout}
                            component={MediaExplorePage}
                        />
                        <Redirect from="/media/explore" exact={true} to="/media/explore/0" />
                        <StandardRoute
                            path="/media/view/:id"
                            layout={SideBarLayout}
                            component={MediaViewPage}
                        />
                        <StandardRoute
                            path="/media/annotate/:id"
                            layout={SideBarLayout}
                            component={DirectMediaAnnotation}
                        />
                        <StandardRoute
                            path="/media/task/annotate/:id"
                            layout={SideBarLayout}
                            component={MediaTaskAnnotation}
                        />
                        <PrivateRoute
                            path="/failed-restricted"
                            layout={SideBarLayout}
                            component={() => (
                                <FailedAuth
                                    errorTitle="Access Blocked"
                                    errorText="You don't have permission to access this page. Please contact an
                            administrator"
                                />
                            )}
                        />
                        <PrivateRoute
                            path="/failed"
                            layout={SideBarLayout}
                            component={() => (
                                <FailedAuth
                                    errorTitle="Page failed to load"
                                    errorText="Please contact an
                            administrator"
                                />
                            )}
                        />
                        <StandardRoute
                            path="/profile"
                            layout={SideBarLayout}
                            component={() => (
                                <Profile
                                    user={userAuthStore.user}
                                    onSubmit={userAuthStore.setUser}
                                    canChangeUserType={false}
                                />
                            )}
                        />
                        <AdminRoute path="/admin" layout={SideBarLayout} component={AdminPage} />
                        <AdminRoute
                            path="/cross-validation-admin"
                            layout={SideBarLayout}
                            component={CrossValidationAdminPage}
                        />
                        <AdminRoute
                            path="/cross-validation-explorer/:type/:page"
                            layout={SideBarLayout}
                            component={CrossValidationExplorerPage}
                        />
                        <AdminRoute
                            path="/cross-validation-view/:id"
                            layout={SideBarLayout}
                            component={CrossValidationViewPage}
                        />

                        <AdminRoute
                            path="/cross-validation/users/:userId"
                            layout={SideBarLayout}
                            component={CrossValidationUserModelTypesMenu}
                        />
                        <AdminRoute
                            path="/cross-validation/:userId/:modelType"
                            layout={SideBarLayout}
                            component={CrossValidationUserStats}
                        />
                        <AdminRoute
                            path="/cross-validation/:modelType"
                            layout={SideBarLayout}
                            component={CrossValidationGeneralStats}
                        />
                    </Switch>
                </BrowserRouter>
            </Provider>
        );
    }
}

export default App;
