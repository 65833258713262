import * as d3 from "d3";
import React from "react";
import { InstanceGroupCrossValidation } from "../../annotation/KeyPoint/Image";

interface BoxPlotProps {
    visibleGroups: InstanceGroupCrossValidation[];
    width: number;
    height: number;
    margin: { top: number; right: number; bottom: number; left: number };
    store: any;
}

const jitterWidth = 20; // Jitter for box plot

export class CrossValidationBoxPlot extends React.Component<BoxPlotProps> {
    private svgVisbilityBoxPlot = React.createRef<SVGSVGElement>();

    async componentDidMount(): Promise<void> {
        this.renderBoxPlot();
    }

    async componentDidUpdate(prevProps: Readonly<BoxPlotProps>): Promise<void> {
        this.renderBoxPlot();
    }

    estimateAnnotationVisibilityStats = async () => {
        const { visibleGroups, store } = this.props;

        let visibilityData: { visible: number[]; occluded: number[]; notVisible: number[] } = {
            visible: [],
            occluded: [],
            notVisible: [],
        };

        // Create the box plot data
        visibilityData = await store?.crossValidationStore.imageVisibilityStats(visibleGroups);

        const visbilityBoxPlotStats = Object.entries(visibilityData).map(([key, values]) => {
            const q1 = d3.quantile(values, 0.25) ?? 0;
            const median = d3.quantile(values, 0.5) ?? 0;
            const q3 = d3.quantile(values, 0.75) ?? 0;
            const iqr = q3 - q1;
            const lowerBound = q1 - 1.5 * iqr;
            const upperBound = q3 + 1.5 * iqr;

            const whiskerMin = d3.min(values.filter((d) => d >= lowerBound))!;
            const whiskerMax = d3.max(values.filter((d) => d <= upperBound))!;

            const outliers = values.filter((d) => d < lowerBound || d > upperBound);

            return { key, q1, median, q3, whiskerMin, whiskerMax, outliers, values };
        });

        return visbilityBoxPlotStats;
    };

    renderBoxPlot = async () => {
        const { width, height, margin } = this.props;

        const visbilityBoxPlotStats: {
            key: string;
            q1: number;
            median: number;
            q3: number;
            whiskerMin: number;
            whiskerMax: number;
            outliers: number[];
            values: number[];
        }[] = await this.estimateAnnotationVisibilityStats();

        // Remove previous graph before drawing the new one.
        d3.select(this.svgVisbilityBoxPlot.current).selectAll("*").remove();

        const boxplot = d3
            .select(this.svgVisbilityBoxPlot.current)
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        // Estimate scale range
        const xScale = d3
            .scaleBand()
            .domain(visbilityBoxPlotStats.map((d) => d.key))
            .range([0, width])
            .padding(0.3);

        const yScale = d3
            .scaleLinear()
            .domain([
                d3.min(visbilityBoxPlotStats, (d) => d.whiskerMin)! * 0.8,
                d3.max(visbilityBoxPlotStats, (d) => d.whiskerMax)! * 1.2,
            ])
            .nice()
            .range([height, 0]);

        // Add axis
        boxplot
            .append("g")
            .attr("class", "x-axis")
            .attr("transform", `translate(0,${height})`)
            .call(d3.axisBottom(xScale));

        boxplot.append("g").attr("class", "y-axis").call(d3.axisLeft(yScale));

        //Add titles
        boxplot
            .append("text")
            .attr("transform", "rotate(-90)")
            .attr("x", -height / 2)
            .attr("y", -margin.left / 2)
            .attr("class", "axis-label")
            .style("text-anchor", "middle")
            .text("Number of annotations");

        // Add box
        boxplot
            .append("g")
            .selectAll(".box")
            .data(visbilityBoxPlotStats)
            .enter()
            .append("rect")
            .attr("class", "box")
            .attr("x", (d) => xScale(d.key)!)
            .attr("y", (d) => yScale(d.q3)!)
            .attr("width", xScale.bandwidth())
            .attr("height", (d) => yScale(d.q1) - yScale(d.q3))
            .attr("fill", "#69b3a2");

        // Add medians
        boxplot
            .append("g")
            .selectAll(".median")
            .data(visbilityBoxPlotStats)
            .enter()
            .append("line")
            .attr("class", "median")
            .attr("x1", (d) => xScale(d.key)!)
            .attr("x2", (d) => xScale(d.key)! + xScale.bandwidth())
            .attr("y1", (d) => yScale(d.median)!)
            .attr("y2", (d) => yScale(d.median)!)
            .attr("stroke", "red")
            .attr("stroke-width", 2);

        // Add whiskers
        boxplot
            .append("g")
            .selectAll(".whisker")
            .data(visbilityBoxPlotStats)
            .enter()
            .append("line")
            .attr("class", "whisker")
            .attr("x1", (d) => xScale(d.key)! + xScale.bandwidth() / 2)
            .attr("x2", (d) => xScale(d.key)! + xScale.bandwidth() / 2)
            .attr("y1", (d) => yScale(d.whiskerMin)!)
            .attr("y2", (d) => yScale(d.whiskerMax)!)
            .attr("stroke", "black")
            .attr("stroke-width", 1);

        // Add circles with the information
        boxplot
            .selectAll("circle")
            .data(visbilityBoxPlotStats.flatMap((d) => d.values.map((val) => ({ ...d, val }))))
            .enter()
            .append("circle")
            .attr(
                "cx",
                (d) =>
                    xScale(d.key)! +
                    xScale.bandwidth() / 2 -
                    jitterWidth / 2 +
                    Math.random() * jitterWidth
            )
            .attr("cy", (d) => yScale(d.val))
            .attr("r", 5)
            .style("fill", "white")
            .attr("stroke", "black");
    };

    render() {
        return <svg ref={this.svgVisbilityBoxPlot} style={{ marginLeft: "100px" }}></svg>;
    }
}
