import React from "react";
import { Container, Menu } from "semantic-ui-react";
import { NavLink, Switch } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { AdminRoute } from "../auth/AuthRoutes";
import { CrossValidationImageMenu } from "./images/CrossValidationImageMenu";
import { CrossValidationUserMenu } from "./users/CrossValidationUserMenu";
import { CrossValidationGeneralStatsMenu } from "./generalStats/CrossValidationGeneralStatsMenu";

export const CrossValidationAdminPage = inject("store")(
    observer(() => {
        React.useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return (
            <Container className="main-content">
                <Menu secondary>
                    <Menu.Item as={NavLink} to={`/cross-validation-admin/image`}>
                        Image Stats
                    </Menu.Item>
                    <Menu.Item as={NavLink} to={`/cross-validation-admin/user`}>
                        User Stats
                    </Menu.Item>
                    <Menu.Item as={NavLink} to={`/cross-validation-admin/general`}>
                        General Stats
                    </Menu.Item>
                </Menu>
                <Switch>
                    <AdminRoute
                        path="/cross-validation-admin/image"
                        component={CrossValidationImageMenu}
                    />
                    <AdminRoute
                        path="/cross-validation-admin/user"
                        component={CrossValidationUserMenu}
                    />
                    <AdminRoute
                        path="/cross-validation-admin/general"
                        component={CrossValidationGeneralStatsMenu}
                    />
                </Switch>
            </Container>
        );
    })
);
