export const keypoints_tennis_racket = [
    { cx: 430, cy: 40, joint: "Head" },
    { cx: 238, cy: 68, joint: "Left Edge" },
    { cx: 396, cy: 214, joint: "Right Edge" },
    { cx: 245, cy: 268, joint: "Right Corner" },
    { cx: 188, cy: 208, joint: "Left Corner" },
    { cx: 135, cy: 320, joint: "Shaft" },
    { cx: 25, cy: 428, joint: "Handle" },
];

export const keypoint_baseball_bat = [
    { cx: 445, cy: 15, joint: "Head" },
    { cx: 25, cy: 428, joint: "Handle" },
];

export const keypoint_humans = [
    { cx: 237, cy: 32, joint: "Nose" },
    { cx: 229, cy: 25, joint: "Right Eye" },
    { cx: 244, cy: 25, joint: "Left Eye" },
    { cx: 218, cy: 35, joint: "Right Ear " },
    { cx: 256, cy: 35, joint: "Left Ear" },
    { cx: 236.5, cy: 70, joint: "Neck" },
    { cx: 200, cy: 70, joint: "Right Shoulder" },
    { cx: 275, cy: 70, joint: "Left Shoulder" },
    { cx: 120, cy: 100, joint: "Right Elbow" },
    { cx: 350, cy: 100, joint: "Left Elbow" },
    { cx: 65, cy: 115, joint: "Right Wrist" },
    { cx: 405, cy: 115, joint: "Left Wrist" },
    { cx: 44, cy: 132, joint: "Right Thumb" },
    { cx: 425, cy: 132, joint: "Left Thumb" },
    { cx: 30, cy: 135, joint: "Right Pinky" },
    { cx: 440, cy: 135, joint: "Left Pinky" },
    { cx: 210, cy: 225, joint: "Right Hip" },
    { cx: 265, cy: 225, joint: "Left Hip" },
    { cx: 200, cy: 325, joint: "Right Knee" },
    { cx: 275, cy: 325, joint: "Left Knee" },
    { cx: 200, cy: 400, joint: "Right Ankle" },
    { cx: 275, cy: 400, joint: "Left Ankle" },
    { cx: 210, cy: 415, joint: "Right Heel" },
    { cx: 265, cy: 415, joint: "Left Heel" },
    { cx: 200, cy: 432, joint: "Right BigToe" },
    { cx: 275, cy: 432, joint: "Left BigToe" },
    { cx: 180, cy: 432, joint: "Right SmallToe" },
    { cx: 295, cy: 432, joint: "Left SmallToe" },
];

export const keypoint_humans_hockey_sticks = [
    { cx: 203.5, cy: 30, joint: "Nose" },
    { cx: 196, cy: 25, joint: "Right Eye" },
    { cx: 211, cy: 25, joint: "Left Eye" },
    { cx: 185, cy: 32, joint: "Right Ear " },
    { cx: 221, cy: 32, joint: "Left Ear" },
    { cx: 202.5, cy: 65, joint: "Neck" },
    { cx: 164, cy: 65, joint: "Right Shoulder" },
    { cx: 240, cy: 65, joint: "Left Shoulder" },
    { cx: 95, cy: 90, joint: "Right Elbow" },
    { cx: 305, cy: 90, joint: "Left Elbow" },
    { cx: 50, cy: 105, joint: "Right Wrist" },
    { cx: 355, cy: 105, joint: "Left Wrist" },
    { cx: 30, cy: 120, joint: "Right Thumb" },
    { cx: 372, cy: 120, joint: "Left Thumb" },
    { cx: 18, cy: 118, joint: "Right Pinky" },
    { cx: 382, cy: 115, joint: "Left Pinky" },
    { cx: 180, cy: 205, joint: "Right Hip" },
    { cx: 225, cy: 205, joint: "Left Hip" },
    { cx: 170, cy: 290, joint: "Right Knee" },
    { cx: 235, cy: 290, joint: "Left Knee" },
    { cx: 170, cy: 360, joint: "Right Ankle" },
    { cx: 238, cy: 360, joint: "Left Ankle" },
    { cx: 175, cy: 375, joint: "Right Heel" },
    { cx: 230, cy: 375, joint: "Left Heel" },
    { cx: 165, cy: 390, joint: "Right BigToe" },
    { cx: 240, cy: 390, joint: "Left BigToe" },
    { cx: 150, cy: 390, joint: "Right SmallToe" },
    { cx: 255, cy: 390, joint: "Left SmallToe" },
    { cx: 300, cy: 220, joint: "Handle" },
    { cx: 410, cy: 370, joint: "Curve" },
    { cx: 440, cy: 372, joint: "Tip" },
];

export const keypoint_humans_tennis_rackets = [
    { cx: 230.5, cy: 30, joint: "Nose" },
    { cx: 223, cy: 25, joint: "Right Eye" },
    { cx: 239, cy: 25, joint: "Left Eye" },
    { cx: 212, cy: 35, joint: "Right Ear " },
    { cx: 250, cy: 35, joint: "Left Ear" },
    { cx: 230.5, cy: 70, joint: "Neck" },
    { cx: 194, cy: 70, joint: "Right Shoulder" },
    { cx: 270, cy: 70, joint: "Left Shoulder" },
    { cx: 105, cy: 100, joint: "Right Elbow" },
    { cx: 340, cy: 100, joint: "Left Elbow" },
    { cx: 60, cy: 115, joint: "Right Wrist" },
    { cx: 395, cy: 115, joint: "Left Wrist" },
    { cx: 38, cy: 134, joint: "Right Thumb" },
    { cx: 420, cy: 134, joint: "Left Thumb" },
    { cx: 30, cy: 128, joint: "Right Pinky" },
    { cx: 430, cy: 128, joint: "Left Pinky" },
    { cx: 200, cy: 230, joint: "Right Hip" },
    { cx: 258, cy: 230, joint: "Left Hip" },
    { cx: 195, cy: 320, joint: "Right Knee" },
    { cx: 265, cy: 320, joint: "Left Knee" },
    { cx: 190, cy: 400, joint: "Right Ankle" },
    { cx: 270, cy: 400, joint: "Left Ankle" },
    { cx: 200, cy: 415, joint: "Right Heel" },
    { cx: 260, cy: 415, joint: "Left Heel" },
    { cx: 190, cy: 435, joint: "Right BigToe" },
    { cx: 270, cy: 435, joint: "Left BigToe" },
    { cx: 173, cy: 435, joint: "Right SmallToe" },
    { cx: 288, cy: 435, joint: "Left SmallToe" },
    { cx: 306, cy: 345, joint: "Handle" },
    { cx: 340, cy: 310, joint: "Shaft" },
    { cx: 375, cy: 295, joint: "Left Corner" },
    { cx: 355, cy: 281, joint: "Right Corner" },
    { cx: 420, cy: 275, joint: "Left Edge" },
    { cx: 372, cy: 231, joint: "Right Edge" },
    { cx: 428, cy: 220, joint: "Head" },
];
export const keypoints_cricket_bat = [
    { cx: 28, cy: 10, joint: "Handle" },
    { cx: 175, cy: 160, joint: "Shaft" },
    { cx: 205, cy: 138, joint: "Left Edge" },
    { cx: 150, cy: 182, joint: "Right Edge" },
    { cx: 428, cy: 415, joint: "Head" },
];

export const keypoints_basketball_ball = [
    { cx: 15, cy: 90, joint: "Logo Keypoint 1" },
    { cx: 155, cy: 115, joint: "Logo Keypoint 2" },
    { cx: 5, cy: 162, joint: "Logo Keypoint 3" },
    { cx: 140, cy: 183, joint: "Logo Keypoint 4" },
    { cx: 305, cy: 255, joint: "Logo Keypoint 1" },
    { cx: 448, cy: 243, joint: "Logo Keypoint 2" },
    { cx: 315, cy: 328, joint: "Logo Keypoint 3" },
    { cx: 452, cy: 312, joint: "Logo Keypoint 4" },
    { cx: 225, cy: 125, joint: "Intersection Keypoint 1" },
    { cx: 218, cy: 183, joint: "Intersection Keypoint 2" },
    { cx: 252, cy: 298, joint: "Intersection Keypoint 3" },
    { cx: 230, cy: 298, joint: "Intersection Keypoint 4" },
];

export const keypoints_hands = [
    { cx: 320, cy: 55, joint: "Elbow" },
    { cx: 245, cy: 260, joint: "Wrist" },
    { cx: 205, cy: 265, joint: "Thumb Knuckle" },
    { cx: 175, cy: 290, joint: "Thumb PIP" },
    { cx: 158, cy: 320, joint: "Thumb DIP" },
    { cx: 150, cy: 345, joint: "Thumb Tip" },
    { cx: 189, cy: 350, joint: "Index Finger Knuckle" },
    { cx: 180, cy: 380, joint: "Index Finger PIP" },
    { cx: 174, cy: 398, joint: "Index Finger DIP" },
    { cx: 168, cy: 410, joint: "Index Finger Tip" },
    { cx: 215, cy: 360, joint: "Middle Finger Knuckle" },
    { cx: 208, cy: 390, joint: "Middle Finger PIP" },
    { cx: 200, cy: 408, joint: "Middle Finger DIP" },
    { cx: 195, cy: 420, joint: "Middle Finger Tip" },
    { cx: 240, cy: 360, joint: "Ring Finger Knuckle" },
    { cx: 235, cy: 393, joint: "Ring Finger PIP" },
    { cx: 230, cy: 408, joint: "Ring Finger DIP" },
    { cx: 224, cy: 420, joint: "Ring Finger Tip" },
    { cx: 260, cy: 360, joint: "Pinky Knuckle" },
    { cx: 260, cy: 375, joint: "Pinky PIP" },
    { cx: 258, cy: 390, joint: "Pinky DIP" },
    { cx: 252, cy: 402, joint: "Pinky Tip" },
];

export function getKeypointImagesCircles(modelType: string) {
    switch (modelType) {
        case "keypoints_tennis_racket":
        case "keypoints_badminton_racket":
            return keypoints_tennis_racket;
        case "keypoint_baseball_bat":
            return keypoint_baseball_bat;
        case "keypoint_humans":
            return keypoint_humans;
        case "keypoint_humans+hockey_sticks":
            return keypoint_humans_hockey_sticks;
        case "keypoint_humans+tennis_rackets":
            return keypoint_humans_tennis_rackets;
        case "keypoints_basketball_ball":
            return keypoints_basketball_ball;
        case "keypoints_cricket_bat":
            return keypoints_cricket_bat;
        case "keypoints_hands":
            return keypoints_hands;
        default:
            return [];
    }
}
