import { inject, observer } from "mobx-react";
import React from "react";
import { ProvidedAppStore } from "../../../store/AppStore";
import { CrossValidationStore } from "../../../store/CrossValidationStore";
import { Accordion, Button, Form, Icon, Input } from "semantic-ui-react";
import { dropdownInputSelection, tagCheckboxSelection } from "../../helpers/FilterInputComponents";
import { CameraType, ImageScanType } from "../../../models/Image";

type Props = ProvidedAppStore;

@inject("store")
@observer
export class CrossValidationImageFilter extends React.Component<Props> {
    store: CrossValidationStore | undefined;

    constructor(props: Props) {
        super(props);
        this.store = props.store?.crossValidationStore;
    }

    async componentDidMount() {
        if (!this.store) return;
        await this.store.fetchFilterOptions(this.store.typeModelName);
    }

    async handleChange(event: any, data: any) {
        if (!this.store) return;

        const { name, value } = data;
        const filterState = { ...this.store.filterState, [name]: value };
        this.store.updateFilterState(filterState);

        if (name === "addSports") {
            this.store.fetchFilterOptions(this.store.typeModelName);
        }
    }

    async handleCheckboxChange(event: any) {
        if (!this.store) return;

        let tags = [...this.store.filterState.tags];
        if (event.target.checked) {
            tags.push(event.target.value);
        } else {
            tags = tags.filter((tag) => tag !== event.target.value);
        }
        await this.handleChange(null, { name: "tags", value: tags });
    }

    async fetchFilteredCrossValidation() {
        if (!this.store) return;
        this.store.fetchFilteredCrossValidationIdsAndCount();
    }

    isTagSelected(tag: string) {
        if (!this.store) return false;
        return this.store.filterState.tags.includes(tag);
    }

    render() {
        const store = this.props.store!.crossValidationStore;
        const { filterOpen, filterState } = store;

        return (
            <Accordion styled={true} fluid={true}>
                <Accordion.Title
                    active={filterOpen}
                    index={0}
                    onClick={() => {
                        store.toggleFilterOpen();
                    }}
                >
                    <Icon name="dropdown" />
                    Filter Cross Validation Images
                </Accordion.Title>
                <Accordion.Content active={filterOpen}>
                    <Form>
                        <Form.Group widths="equal">
                            {dropdownInputSelection(
                                "addSports",
                                filterState.addSports,
                                store.filterOptions.availableSports,
                                (e, d) => this.handleChange(e, d)
                            )}
                            {dropdownInputSelection(
                                "addStadiums",
                                filterState.addStadiums,
                                store.filterOptions.availableStadiums,
                                (e, d) => this.handleChange(e, d)
                            )}
                        </Form.Group>
                        <Form.Group widths="equal">
                            {dropdownInputSelection(
                                "cameraTypes",
                                filterState.cameraTypes,
                                Object.keys(CameraType),
                                (e, d) => this.handleChange(e, d)
                            )}
                            {dropdownInputSelection(
                                "imageScanTypes",
                                filterState.imageScanTypes,
                                Object.keys(ImageScanType),
                                (e, d) => this.handleChange(e, d)
                            )}
                            {dropdownInputSelection(
                                "applications",
                                filterState.applications,
                                store.filterOptions.availableApplications,
                                (e, d) => this.handleChange(e, d)
                            )}
                        </Form.Group>
                        <Form.Group inline widths="equal">
                            <Form.Field>
                                <Input
                                    type="date"
                                    id="startDate"
                                    name="startDate"
                                    label="Start Date"
                                    onChange={(event: any, data: any) => {
                                        data["value"] = new Date(data["value"]);
                                        this.handleChange(event, data);
                                    }}
                                    defaultValue={filterState.startDate}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Input
                                    type="date"
                                    id="endDate"
                                    name="endDate"
                                    label="End Date"
                                    onChange={(event: any, data: any) => {
                                        data["value"] = new Date(data["value"]);
                                        this.handleChange(event, data);
                                    }}
                                    defaultValue={filterState.endDate}
                                />
                            </Form.Field>
                        </Form.Group>
                        {tagCheckboxSelection(
                            store.filterOptions.availableTags,
                            (tag) => this.isTagSelected(tag),
                            (event) => this.handleCheckboxChange(event)
                        )}
                    </Form>
                    <Button
                        loading={store.requestPending}
                        disabled={store.requestPending}
                        onClick={() => this.fetchFilteredCrossValidation()}
                        fluid
                        color={"green"}
                        basic
                    >
                        Submit
                    </Button>
                </Accordion.Content>
            </Accordion>
        );
    }
}
